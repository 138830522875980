import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slugify from "react-slugify";
import { useSelector, useDispatch } from "react-redux";
import { fetchMenuFeedApi } from "../store/MenuFeedaction";
import { useParams } from "react-router";
import { useNavigate, Link } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Constant from "../Constants";
import {
  postInitializeQuotesApi,
  putQuotesUpdateItemsApi,
} from "../store/InitializeQuotesPostAPI";
import {
  incrementQuantity,
  decrementQuantity,
  changeVariant,
} from "../store/slice/MenuFeedAPISlice";
import { selectWishlist } from "../store/slice/wishlistSlice";

const CategoryProducts = () => {

  const { categoryId } = useParams()
  const [disabledButtons, setDisabledButtons] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { info, loading, error } = useSelector(
    (state) => state.menuFeed || {}
  );
  const [visibleProducts, setVisibleProducts] = useState(12);
  const productsPerPage = 12;
  const [sortOrder, setSortOrder] = useState("");
  const [sortedProducts, setSortedProducts] = useState([]);
  const isMedicalOnly = localStorage.getItem("menuType") === "Recreational" ? false : true
  const slugId = useSelector(
    (store) => store?.merchant?.info?.merchant?.venues.filter(vn => vn.selected)[0]?.slug
  );
  const defaultSlugId = slugId;

  const reference_no = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.reference_no
  );

  const items = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.items
  );
  // Wishlist product
  const wishlistItems = useSelector(selectWishlist);
  const isInWishlist = (productId, variantId) => {

    if (!wishlistItems) {
      return false;
    }
    return wishlistItems.some(item => item.pId === productId && item.vId === variantId);
  };
  // Variant change handler

  const handleVariantChange = (e, pId) => {
    dispatch(changeVariant({ productId: pId, variantId: e.target.value }));
  };

  /* Add to cart */

  const addToCartitem = async (item, vari) => {
    // Disable the button
    setDisabledButtons((prevDisabledButtons) => ({
      ...prevDisabledButtons,
      [item.id]: true,
    }));
    try {
      const slugId = localStorage.getItem("selectedVenue") || defaultSlugId;
      const existingCartItem = items?.find(
        (it) =>
          it.sku_id === vari?.sku_id &&
          it.unit === vari?.unit &&
          it.unit_type === vari?.unit_type
      );

      if (
        existingCartItem &&
        existingCartItem.quantity === vari?.quantity_on_hand
      ) {
        toast.warning("You have reached the maximum quantity");
      } else {
        if (reference_no) {
          const found = items.filter((it) => {
            if (
              it.sku_id === vari?.sku_id &&
              it.unit === vari?.unit &&
              it.unit_type === vari?.unit_type
            ) {
              return it;
            }
          });
          if (found.length > 0) {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    ...items.map((it) => {
                      if (
                        it.sku_id === vari?.sku_id &&
                        it.unit === vari?.unit &&
                        it.unit_type === vari?.unit_type
                      ) {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity:
                            it?.quantity + vari?.quantity >
                              vari?.quantity_on_hand
                              ? vari?.quantity_on_hand
                              : it?.quantity + vari?.quantity,
                        };
                      } else {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity: it?.quantity,
                        };
                      }
                    }),
                  ],
                },
              })
            );
            toast.success("Product quantity updated in cart");
          } else {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    {
                      sku_id: vari?.sku_id,
                      unit: vari?.unit,
                      unit_type: vari?.unit_type,
                      quantity: vari?.quantity,
                    },
                    ...items.map((it) => {
                      return {
                        sku_id: it?.sku_id,
                        unit: it?.unit,
                        unit_type: it?.unit_type,
                        quantity: it?.quantity,
                      };
                    }),
                  ],
                },
              })
            );
            toast.success("Product Added to cart");
          }
        } else {
          dispatch(
            postInitializeQuotesApi({
              slug: slugId,
              quote: {
                items: [
                  {
                    sku_id: vari?.sku_id,
                    unit: vari?.unit,
                    unit_type: vari?.unit_type,
                    quantity: vari?.quantity,
                  },
                ],
              },
            })
          );
          toast.success("Product Added to cart");
        }
      }
      setTimeout(() => {
        setDisabledButtons((prevDisabledButtons) => ({
          ...prevDisabledButtons,
          [item.id]: false,
        }));
      }, 1500);
    } catch (error) {
      // Handle error
      setDisabledButtons((prevDisabledButtons) => ({
        ...prevDisabledButtons,
        [item.id]: false,
      }));
    }
    // setShowModal(true);
  };

  /* End Add to cart */

  // Increment quantity handler
  const incrementQuantityHandler = (currentProSelectedVariant) => {
    if (currentProSelectedVariant?.quantity_on_hand > 0) {
      if (
        currentProSelectedVariant?.quantity <
        currentProSelectedVariant?.quantity_on_hand
      ) {
        dispatch(incrementQuantity({ variant: currentProSelectedVariant }));
      } else {
        toast.warning("No more quantity in stock");
      }
    }
  };

  const decrementQuantityHandler = (currentProSelectedVariant) => {
    if (currentProSelectedVariant?.quantity > 1) {
      dispatch(decrementQuantity({ variant: currentProSelectedVariant }));
    } else {
      toast.warning("Minimum 1 quantity required");
    }
  };
  // End Increment quantity handler

  useEffect(() => {
    const storedSlugId = localStorage.getItem("selectedVenue");

    const slugId = storedSlugId || defaultSlugId;
    dispatch(fetchMenuFeedApi(slugId));
  }, [dispatch, categoryId]);
  // Find the category name based on the categoryId
  const category = info?.product_data?.categories.find(cat => cat.id === parseInt(categoryId));
  const categoryName = category ? category.name : "Unknown Category";

  useEffect(() => {
    // Filter the products based on the category ID and medical flag
    const filteredProducts = info?.product_data?.menu_items?.filter((product) => {
      const isInCategory = product?.category_ids.includes(parseInt(categoryId));
      // Check if medical filter is active and if the product is marked as medical-only
      if (isMedicalOnly) {
        return isInCategory && product.is_medical_only;
      }
      // If not applying the medical filter, show all products in the category
      return isInCategory;
    });
    // Sort the products based on the selected sort order
    const sorted = sortProducts(filteredProducts, sortOrder);
    setSortedProducts(sorted);
  }, [info, categoryId, sortOrder, isMedicalOnly]);


  // Function to sort products 
  const sortProducts = (products, order) => {
    if (order === "asc") {

      return products.slice().sort((a, b) => a.variants[0].name.localeCompare(b.variants[0].name));

    } else if (order === "desc") {

      return products.slice().sort((a, b) => b.variants[0].name.localeCompare(a.variants[0].name));

    } else if (order === "price-asc") {

      return products.slice().sort((a, b) => a.variants[0].price_cents - b.variants[0].price_cents);

    } else if (order === "price-desc") {

      return products.slice().sort((a, b) => b.variants[0].price_cents - a.variants[0].price_cents);

    } else if (order === "thc-low-high") {

      return products.slice().sort((a, b) => a.test_results.thc.current - b.test_results.thc.current);
    } else if (order === "thc-high-low") {

      return products.slice().sort((a, b) => b.test_results.thc.current - a.test_results.thc.current);
    } else if (order === "cbd-low-high") {

      return products.slice().sort((a, b) => a.test_results.cbd.current - b.test_results.cbd.current);
    } else if (order === "cbd-high-low") {

      return products.slice().sort((a, b) => b.test_results.cbd.current - a.test_results.cbd.current);

    } else if (order === "popularity-high-low") {

      return products.slice().sort((a, b) => a.popularity - b.popularity);

    } else if (order === "popularity-low-high") {

      return products.slice().sort((a, b) => b.popularity - a.popularity);
    }

    return products;
  };

  //SDP Data
  const sdpData = useSelector((store) => store?.sdpData?.info?.categories);
  //End SDP Data

  const loadMoreProducts = () => {
    setVisibleProducts((prev) => prev + productsPerPage);
  };
  const handleSortOrderChange = (e) => {
    const selectedSortOrder = e.target.value;
    setSortOrder(selectedSortOrder)
    dispatch(fetchMenuFeedApi(slugId));
  };

  if (loading) {
    return <div>{Constant.LOADING}</div>;
  }

  if (error) {
    return <div>{Constant.ERROR} {error}</div>;
  }

  return (
    <>
      <section id="product_section">

        <div className="container">
          <div className="back-btn">
            <Link onClick={() => navigate(-1)}>
              <i className="fa fa-arrow-left"></i> Back
            </Link>
          </div>
          <div className="m-2">
            <div className="d-flex justify-content-between">

              <nav aria-label="breadcrumb">

                <p className="deals">{categoryName}</p>
              </nav>

              <div className="float-right">
                <select
                  className="form-select"
                  onChange={handleSortOrderChange}
                  value={sortOrder}
                >
                  <option value="">{Constant.SORT_BY}</option>
                  <option value="asc">{Constant.A_Z}</option>
                  <option value="price-asc">
                    {Constant.PRICE_LOW_TO_HIGH}
                  </option>
                  <option value="price-desc">
                    {Constant.PRICE_HIGH_TO_LOW}
                  </option>
                  <option value="thc-low-high">
                    {Constant.THC_LOW_TO_HIGH}
                  </option>
                  <option value="thc-high-low">
                    {Constant.THC_HIGH_TO_LOW}
                  </option>
                  <option value="cbd-low-high">
                    {Constant.CBD_LOW_TO_HIGH}
                  </option>
                  <option value="cbd-high-low">
                    {Constant.CBD_HIGH_TO_LOW}
                  </option>
                  <option value="popularity-high-low">
                    {Constant.MOST_POPULAR}
                  </option>
                  <option value="popularity-low-high">
                    {Constant.LEAST_POPULAR}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div>
            {sortedProducts && sortedProducts.length > 0 ? (
              sortedProducts.slice(0, visibleProducts).map((product) => {
                const discountIdForMedPrice =
                  product.variants[0].discount_id_for_med_price;
                const discountIdForPrice =
                  product.variants[0].discount_id_for_price;

                const discountForMedPrice = info?.product_data?.discounts.find(
                  (d) => d.id === discountIdForMedPrice
                );
                const discountForPrice = info?.product_data?.discounts.find(
                  (d) => d.id === discountIdForPrice
                );

                const discountNameForMedPrice = discountForMedPrice?.item_display_name;
                const discountNameForPrice = discountForPrice?.item_display_name;

                const chosenDiscountName =
                  discountNameForMedPrice || discountNameForPrice;

                const selectedVariant = product.variants.filter(vr => vr?.selected)
                const sdpCategoryIcon = sdpData?.find(
                  (category) => category?.sdp_category === product?.sdp_category
                )?.icon_url;

                return (
                  <div className="cart_section" key={product.id}>
                    <div className="product_review_page">
                      {chosenDiscountName && (
                        <div className="off ">{chosenDiscountName}</div>
                      )}

                      <div className="product_image_section">
                        <img
                          src={product?.product_image?.lg}
                          alt="Product_Image"
                          onClick={() => navigate(product?.brand_id
                            ? `/${slugify(product?.brand)}/${slugify(product?.variants[0]?.name)}`
                            : `/${slugify(product?.variants[0]?.name)}`, {
                            state: {
                              brands: product?.brand_id,
                              product_id: product?.product_id,
                            }
                          })}
                        />

                      </div>
                      <div className="wrap">
                        <div className="product-description">
                          <div className="product_review_details">
                            <h5 className="name">
                              {selectedVariant[0]?.name}{" "}
                            </h5>

                            <div className="d-flex">
                              {(product.flower_type && product.flower_type.length !== 0) && (
                                <React.Fragment>
                                  <p className="indica">{product?.flower_type}</p>{" "}

                                </React.Fragment>
                              )}
                              <span>Dr. Jolly's</span>
                            </div>

                            <div className="total-section">
                              <div className="pMeta_wrap">
                                {product?.test_results?.total_thc?.current !== 0.0 && (
                                  <span className="item">
                                    {Constant.TOTAL_THC} :{" "}
                                    {product?.test_results?.total_thc?.current} {product?.test_results?.total_thc?.unit_type}
                                  </span>
                                )}
                              </div>
                              <div className="img-section">
                                <img src={sdpCategoryIcon ? sdpCategoryIcon : "/images/strain-data.png"} alt="sdp_icon" />
                              </div>
                            </div>
                            <div className="weight-quantity">
                              <div className="select_box">
                                <p className="quantity">{Constant.WEIGHT}</p>

                                {product?.variants?.length > 1 ? (
                                  <>
                                    <select
                                      className="form-control form-select"
                                      value={selectedVariant[0]?.id}
                                      onChange={(e) => handleVariantChange(e, product?.id)}
                                    >
                                      {product?.variants?.map((vt) => (
                                        <option key={vt?.id} value={vt?.id}>
                                          {`${vt?.unit} ${vt?.unit_type}`}
                                        </option>
                                      ))}
                                    </select>
                                  </>
                                ) : (
                                  // If only one variant, display its details
                                  <span>{`${product?.variants[0]?.unit} ${product?.variants[0]?.unit_type}`}</span>
                                )}
                              </div>

                              <div className="plus_minus_box">
                                <p className="quantity quantityP">Quantity</p>
                                <div className="plus_minus">
                                  <span
                                    className={`minus ${selectedVariant[0]?.quantity <= 1
                                      ? "disabled"
                                      : ""
                                      }`}
                                    onClick={() => decrementQuantityHandler(selectedVariant[0])}
                                  >
                                    -
                                  </span>

                                  <span className="value">
                                    {selectedVariant[0]?.quantity}
                                  </span>

                                  <span
                                    className={`add ${selectedVariant[0]?.quantity_on_hand <= 0
                                      ? "disabled"
                                      : ""
                                      }`}
                                    onClick={() => incrementQuantityHandler(selectedVariant[0])}
                                  >
                                    +
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="product_total_section">
                          <h5>
                            {selectedVariant[0]?.discounted_med_price_cents ||
                              selectedVariant[0]?.discounted_price_cents ? (
                              <span>
                                    <span className="price">
                                  &nbsp;&nbsp;$
                                  {(
                                    selectedVariant[0]?.discounted_med_price_cents / 100 ||
                                    selectedVariant[0]?.discounted_price_cents / 100
                                  ).toFixed(2)}
                                </span>
                                <br />
                                <strike className="strike-price">
                                  ${(
                                    selectedVariant[0]?.med_price_cents / 100 ||
                                    selectedVariant[0]?.price_cents / 100
                                  ).toFixed(2)}
                                </strike>
                              
                            
                              </span>
                            ) : (
                              <span className="price">
                                $
                                {(
                                  selectedVariant[0]?.price_cents / 100 ||
                                  selectedVariant[0]?.med_price_cents / 100
                                ).toFixed(2)}
                              </span>
                            )}
                          </h5>
                          <div className="button-group">

                            <button
                              className="btn btn-info Wishlistbtn"
                              onClick={() => addToCartitem(product, selectedVariant[0])}
                              disabled={disabledButtons[product.id]}
                            ><i className="fa fa-cart-shopping prd_dtl"></i>
                              {Constant.ADD_TO_CART}{" "}

                            </button>

                          </div>
                        </div>
                      </div>
                      <div className="mob_action">
                        <div className="select_box">
                          <p className="quantity">Weight</p>
                          {product?.variants?.length > 1 ? (
                            <>
                              <select
                                className="form-control form-select"
                                value={selectedVariant[0]?.id}
                                onChange={(e) => handleVariantChange(e, product?.id)}
                              >
                                {product?.variants?.map((vt) => (
                                  <option key={vt?.id} value={vt?.id}>
                                    {`${vt?.unit} ${vt?.unit_type}`}
                                  </option>
                                ))}
                              </select>
                            </>
                          ) : (
                            // If only one variant, display its details
                            <span>{`${product?.variants[0]?.unit} ${product?.variants[0]?.unit_type}`}</span>
                          )}
                        </div>

                        <div className="plus_minus_box">
                          <p className="quantity quantityP">Quantity</p>
                          <div className="plus_minus">
                            <span
                              className={`minus ${selectedVariant[0]?.quantity <= 1
                                ? "disabled"
                                : ""
                                }`}
                              onClick={() => decrementQuantityHandler(selectedVariant[0])}
                            >
                              -
                            </span>

                            <span className="value">
                              {selectedVariant[0]?.quantity}
                            </span>

                            <span
                              className={`add ${selectedVariant[0]?.quantity_on_hand <= 0
                                ? "disabled"
                                : ""
                                }`}
                              onClick={() => incrementQuantityHandler(selectedVariant[0])}
                            >
                              +
                            </span>
                          </div>
                        </div>
                        <div className="button-group">
                          <button className="btn btn-info Wishlistbtn"
                            onClick={() => addToCartitem(product, selectedVariant[0])}
                            disabled={disabledButtons[product.id]}
                          >
                            <i className="fa fa-cart-shopping prd_dtl"></i> Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>{Constant.NO_PRODUCTS_FOUND}</div>
            )}

            {/* Load more button */}
            {visibleProducts < sortedProducts?.length && (
              <div className="col-md-12 text-center">
                <button className="btn Load_more" onClick={loadMoreProducts}>
                  <i className="fa fa-refresh"></i> {Constant.LOAD_MORE}
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* {showModal && (
        <MiniCart showModal={showModal} setShowModal={setShowModal} />
      )} */}
    </>
  );
};


export default CategoryProducts;