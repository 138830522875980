import { createSlice } from "@reduxjs/toolkit";
import { getDiscountData } from "../discountAction";

const discountData = createSlice({
  name: "discount",
  initialState: {
    info: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDiscountData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDiscountData.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(getDiscountData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default discountData.reducer;