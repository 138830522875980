import { createSlice } from "@reduxjs/toolkit";
import { postInitializeQuotesApi } from "../InitializeQuotesPostAPI";
import { v4 as uuidv4 } from "uuid";

const initializeApiSlice = createSlice({
  name: "initializeQuotes",
  initialState: {
    info: null,
    loading: false,
    error: null,
  },
  reducers: {
    emptyInitializeQuotes:(state, action) => {
      state.info = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postInitializeQuotesApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postInitializeQuotesApi.fulfilled, (state, action) => {
        state.loading = false;
        state.info = {
          ...action.payload,
          quote: {
            ...action.payload.quote,
            items: action.payload.quote.items.map((item) => {
              return { ...item, id: uuidv4() };
            }),
          },
        };
      })
      .addCase(postInitializeQuotesApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {emptyInitializeQuotes} = initializeApiSlice.actions

export default initializeApiSlice.reducer;