import React from "react";
import { useSelector } from "react-redux";
import TopBar from "./TopBar";
import MainHeader from "./MainHeader";
import NavBar from "./NavBar";
import { headerColor } from '../store/slice/ContentSlice.js';
import { seondaryColor } from '../store/slice/ContentSlice.js';

const Header = () => {
  const headerColorValue = useSelector(headerColor);
  const secondaryColorValue = useSelector(seondaryColor);
  document.documentElement.style.setProperty('--header-color', headerColorValue);
  document.documentElement.style.setProperty('--secondary-color', secondaryColorValue);
  return (
    <>
      <header>
        <section>
          <TopBar />
        </section>
        <section id="logo_section">
          <MainHeader />
        </section>
      </header>
      <header className="sticky-top">
        <section id="user_info">
          <NavBar />
        </section>
      </header>
    </>
  )
}

export default Header;