import { createSlice } from '@reduxjs/toolkit';
import { fetchContentData } from '../ContentAction';

const contentSlice = createSlice({
  name: 'content',
  initialState: {
    contentData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchContentData.fulfilled, (state, action) => {
        state.loading = false;
        state.contentData = action.payload;
      })
      .addCase(fetchContentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const content = (state) => state.content.contentData;
export const headerColor = (state) => state.content.contentData?.components[0]?.heading_color;
export const headerTextColor = (state) => state.content.contentData?.components[0]?.text_color;
export const primaryColor = (state) => state.content.contentData?.menu?.primary_color;
export const seondaryColor = (state) => state.content.contentData?.menu?.secondary_color;
export default contentSlice.reducer;
