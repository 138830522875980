import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ logoUrl }) => {
  return (
    
    <div className="logo_image">
      <Link to="/">
        {logoUrl ? (
          <img src={logoUrl} alt='Logo'/>
        ) : (
          <img src={window.location.origin + "/images/logo.png"} alt='Default Logo'/>
        )}
      </Link>
    </div>
  );
};

export default Logo;
