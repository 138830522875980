import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchVenueInfo, selectVenueInfo, selectVenueLoading, selectVenueError } from "../store/slice/venueSlice";
import * as Constant from "../Constants";

const StoreOpenTime = () => {
  const dispatch = useDispatch();
  const venueInfo = useSelector(selectVenueInfo);
  const isLoading = useSelector(selectVenueLoading);
  const error = useSelector(selectVenueError);
  const [currentDayInfo, setCurrentDayInfo] = useState(null);
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlugId = slugId;
  useEffect(() => {
    const storedSlugId = localStorage.getItem("selectedVenue");
    
    const slugId = storedSlugId || defaultSlugId;
    dispatch(fetchVenueInfo(slugId));
  }, [dispatch]);

  useEffect(() => {
    if (venueInfo && venueInfo.venue && venueInfo.venue.store_hours) {
      const today = new Date().toLocaleDateString("en-US", { weekday: "long" });
      const todayHours = venueInfo.venue.store_hours.find(day => day.day.toLowerCase() === today.toLowerCase());
      setCurrentDayInfo(todayHours);
    }
  }, [venueInfo]);

  if (isLoading) {
    return <div className="loading">{Constant.LOADING}</div>;
  }

  if (error) {
    return <div>Something went wrong. {error.message}</div>;
  }

  const isStoreClosed = venueInfo && venueInfo.venue && venueInfo.venue.is_closed;

  return (
    <div className="timing">
      <i className="fa fa-clock"></i>
      {isStoreClosed ? (
        <span>Store Closed</span>
      ) : (
        currentDayInfo ? (
          <span>{currentDayInfo.open} - {currentDayInfo.close}</span>
        ) : (
          <span>Store hours not available for today</span>
        )
      )}
    </div>
  );
};

export default StoreOpenTime;
