import React from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchContentData = createAsyncThunk(
  "content/fetchContentData",
  async (payload, thunkAPI) => {
    const selectedVenue = payload || localStorage.getItem("selectedVenue");

    try {
      const response = await fetch(`https://qa-ecomm-app.posabit.com/mcx/oregon_tops/venue/${selectedVenue}/v1/menu_feeds/${window.posabitmenuwidget.config.feed_id}/content`, {
        headers: {
          merchantToken: window.posabitmenuwidget.config.api_key,
          authorization: "Bearer 1vm1ax-tIxpocYteXD1FWA",
        },
      });

      if (!response.ok) {
        throw new Error("No data found");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);