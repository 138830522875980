import React, { useEffect, useState } from "react";
import * as Constant from '../Constants';
import { useDispatch, useSelector } from "react-redux";
import { fetchContentData } from "../store/ContentAction";
import { getDiscountData } from "../store/discountAction";

const Banner = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const defaultSlugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const slugId = localStorage.getItem("selectedVenue") || defaultSlugId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const contentAction = dispatch(fetchContentData(slugId));
        const discountAction = dispatch(getDiscountData(slugId));

        await Promise.all([contentAction, discountAction]);
      } catch (error) {
        setError(error.message || "Error: No data found");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, slugId]);

  const contentData = useSelector((state) => state?.content?.contentData?.components[1]);
  const loading = useSelector((state) => state.content.loading);

  const discountNames = useSelector(state => {
    const discounts = state?.discountData?.info?.coupons;
    const contentData = state?.content?.contentData?.components[1];
    if (contentData && contentData.slides && Array.isArray(contentData.slides)) {
      return contentData.slides.map((item, index) => {
        const discountId = item.discount_id;
        const discount = discounts.find(d => d.id === discountId);

        return {
          itemId: index,
          discountId,
          discountName: discount ? discount.display_name : null,
        };
      });
    }

    return null;
  });

  if (isLoading) {
    return <img className="mx-auto d-block" src="./images/loading.gif" alt="Loading..." />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!contentData) {
    // Handle the case where no content data is available
    return <p>No data found</p>;
  }

  const { auto_play, auto_play_interval, slides } = contentData;

  const currentDate = new Date();
  const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });

  const filteredSlides = slides?.filter(slide => {
    const slideStart = new Date(`${slide.start_date} ${slide.start_at}`);
    const slideEnd = new Date(`${slide.end_date} ${slide.end_at}`);
    return slide.days.includes(currentDay) && currentDate >= slideStart && currentDate <= slideEnd;
  });

  

  return (
    <section id="slider_section">
      <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval={auto_play_interval * 500}>
        <div className="carousel-inner">
          {filteredSlides?.map((slide, index) => (
            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
              <img src={slide?.file?.url} className="d-block w-100" alt="..." />
              <div className="carousel-caption  d-md-block">
                <h1>{Constant.LEGAL_MARIJUANA_SHOP}</h1>
                {discountNames &&
                  discountNames.map(item => (
                    <h2 key={item.itemId}>
                      {item.discountName}
                    </h2>
                  ))}
                <p>{Constant.SED_VIVERRA}</p>
                {slide.url && <a href={slide.url} className="shop_now">{Constant.SHOP_NOW}</a>}
              </div>
            </div>
          ))}
        </div>
        {auto_play && (
          <>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">{Constant.PREVIOUS}</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">{Constant.NEXT}</span>
            </button>
          </>
        )}
      </div>
    </section>
  );
}

export default Banner;
