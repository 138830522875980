import { createAsyncThunk } from "@reduxjs/toolkit";

export const getDiscountData = createAsyncThunk("discount", async (payload) => {
    const selectedVenue = payload || localStorage.getItem("selectedVenue");

try {
    const response = await fetch(`https://qa-ecomm-app.posabit.com/mcx/oregon_tops/venue/${selectedVenue}/v1/coupons`, {
      headers: {
        merchantToken: window.posabitmenuwidget.config.api_key,
        authorization: "Bearer 1vm1ax-tIxpocYteXD1FWA",
      },
    });

    if (!response.ok) {
      throw new Error("No data found");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  
}
});