import React, { useState, useEffect, useRef } from "react";
import Search from "./Search";
import { useDispatch, useSelector } from "react-redux";
import MiniCart from "./MiniCart.js";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useNavigate } from "react-router-dom";
import {
  selectPriceRange, selectSelectedBrand, selectSelectedCategories,
  selectSelectedType, selectSortOrder, selectSelectedTerpenes, selectSelectedEffects,
  selectSelectedFlavors, selectSelectedStrains, selectSelectedSubcategory, selectSelectedWeight,
} from "../store/slice/MenuFeedAPISlice";
import { fetchMenuFeedApi } from "../store/MenuFeedaction";
import {
  setPriceRange,
  setSelectedBrand,
  setSelectedCategories,
  setSelectedSubcategory,
  setSelectedType,
  setSortOrder,
  setSelectedTerpenes,
  setSelectedFlavors,
  setSelectedEffects,
  setSelectedStrains,
  setSelectedWeight,
} from '../store/slice/MenuFeedAPISlice';
import * as Constant from "../Constants";
import Accordion from 'react-bootstrap/Accordion';
import { selectMenuFeed } from "../store/slice/MenuFeedAPISlice";

const NavBar = () => {
  const [isActive, setIsActive] = useState(false);
  const [showList, setShowList] = useState(false)
  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
    setShowList(true)
  };
  const [isFilterBoxOpen, setIsFilterBoxOpen] = useState(false);
  const [filterCriteriaChanged, setFilterCriteriaChanged] = useState(false);
  const handleFilterBoxToggle = () => {
    setIsFilterBoxOpen((prevIsOpen) => !prevIsOpen);
  };
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);
  const items = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.items
  );

  //Product Count
  const categoryProductCounts = {};
  const subcategoryProductCounts = {};
  const brandProductCounts = {};
  const strainProductCounts = {};
  const typeProductCounts = {};
  const terpeneProductCounts = {};
  const flavorsProductCounts = {};
  const effectsProductCounts = {};
  const displayWeightsCount = {};
  const menuFeedData = useSelector(selectMenuFeed);
  if (menuFeedData) {
    const { product_data } = menuFeedData;

    const { categories, brands, strains, product_types, terpenes, flavors, effects, menu_items } = product_data;

    if (categories) {
      categories.forEach(category => {
        category.subcategories.forEach(subcategory => {
          subcategoryProductCounts[subcategory.id] = 0;
        });
        categoryProductCounts[category.id] = 0;
      });
    }
    if (brands) {
      brands.forEach(brand => {
        brandProductCounts[brand.id] = 0;
      });
    }

    if (strains) {
      strains.forEach(strain => {
        strainProductCounts[strain.id] = 0;
      });
    }
    if (product_types) {
      product_types.forEach(type => {
        typeProductCounts[type.id] = 0;
      });
    }
    if (terpenes) {
      terpenes.forEach(terpene => {
        terpeneProductCounts[terpene.id] = 0;
      });
    }
    if (flavors) {
      flavors.forEach(flavor => {
        flavorsProductCounts[flavor.id] = 0;
      });
    }
    if (effects) {
      effects.forEach(effect => {
        effectsProductCounts[effect.id] = 0;
      });
    }

    // Count the products in each category, brand, and strain
    if (menu_items) {
      menu_items.forEach(item => {

        item.category_ids.forEach(categoryId => {
          categoryProductCounts[categoryId]++;
        });
        item.category_ids.forEach(categoryId => {
          const currentCategory = categories.find(cat => cat.id === categoryId);
          if (currentCategory) {
            currentCategory.subcategories.forEach(subcategory => {
              if (subcategoryProductCounts[subcategory.id] !== undefined && item.subcategory_ids.includes(subcategory.id)) {
                subcategoryProductCounts[subcategory.id]++;
              }
            });
          }
        });
        const { variants } = item;
        if (variants) {
          variants.forEach((variant) => {
            const { display_weight: displayWeight } = variant;
            if (displayWeight) {
              if (!displayWeightsCount[displayWeight]) {
                displayWeightsCount[displayWeight] = 0;
              }
              displayWeightsCount[displayWeight]++;
            }
          });
        }
        if (item.brand_id) {
          brandProductCounts[item.brand_id]++;
        }

        if (item.strain_id) {
          strainProductCounts[item.strain_id]++;
        }
        if (item.product_type_id) {
          typeProductCounts[item.product_type_id]++;
        }
        if (item.terpene_ids) {
          item.terpene_ids.forEach(terpeneId => {
            if (terpeneProductCounts[terpeneId] !== undefined) {
              terpeneProductCounts[terpeneId]++;
            }
          });
        }
        if (item.flavor_ids) {
          item.flavor_ids.forEach(flavorId => {
            if (flavorsProductCounts[flavorId] !== undefined) {
              flavorsProductCounts[flavorId]++;
            }
          });
        }
        if (item.effect_ids) {
          item.effect_ids.forEach(effectId => {
            if (effectsProductCounts[effectId] !== undefined) {
              effectsProductCounts[effectId]++;
            }
          });
        }
      });
    } else {
      console.log('menu_items is undefined');
    }

  } else {
    console.log('menuFeedData is undefined');
  }

  // End Product Count
  // click outside modal
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  // End click outside modal

  const navigate = useNavigate();
  const priceRange = useSelector(selectPriceRange) || [0, 200];
  const [minPrice, setMinPrice] = useState(priceRange[0]);
  const [maxPrice, setMaxPrice] = useState(priceRange[1]);
  const dispatch = useDispatch();

  const selectedBrand = useSelector(selectSelectedBrand) || [];
  const selectedCategories = useSelector(selectSelectedCategories) || [];
  const selectedType = useSelector(selectSelectedType) || [];
  const selectedTerpenes = useSelector(selectSelectedTerpenes) || [];
  const selectedFlavors = useSelector(selectSelectedFlavors) || [];
  const selectedEffects = useSelector(selectSelectedEffects) || [];
  const selectedStrains = useSelector(selectSelectedStrains) || [];
  const sortOrder = useSelector(selectSortOrder);
  const selectedSubcategoryId = useSelector(selectSelectedSubcategory) || [];
  const selectedWeight = useSelector(selectSelectedWeight) || [];

  const weights = useSelector((state) => state.menuFeed.info?.product_data?.display_weights || []);
  const brands1 = useSelector((state) => state.menuFeed.info?.product_data?.brands || []);
  const sortedBrandNames = brands1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const types = useSelector((state) => state.menuFeed.info?.product_data?.product_types || []);
  const sortedTypesNames = types.slice().sort((a, b) => a.name.localeCompare(b.name));
  const categories1 = useSelector((state) => state.menuFeed.info?.product_data?.categories || []);
  const sortedCategoriesNames = categories1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const strains1 = useSelector((state) => state.menuFeed.info?.product_data?.strains || []);
  const sortedStrainsNames = strains1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const terpenes1 = useSelector((state) => state.menuFeed.info?.product_data?.terpenes || []);
  const sortedTerpenesNames = terpenes1.slice().sort((a, b) => a.name.localeCompare(b.name));
  const effects = useSelector((state) => state.menuFeed.info?.product_data?.effects || []);
  const sortedEffectsNames = effects.slice().sort((a, b) => a.name.localeCompare(b.name));
  const flavors1 = useSelector((state) => state.menuFeed.info?.product_data?.flavors || []);
  const sortedFlavorsNames = flavors1.slice().sort((a, b) => a.name.localeCompare(b.name));



  const slugId = useSelector(
    (store) => store?.merchant?.info?.merchant?.venues.filter(vn => vn.selected)[0]?.slug
  );
  const defaultSlug = slugId;
  useEffect(() => {
    // Close the filter box when filter criteria changes
    if (filterCriteriaChanged) {
      setIsFilterBoxOpen(false);
      // Reset the flag
      setFilterCriteriaChanged(false);
    }
  }, [filterCriteriaChanged]);

  const handleApplyFilters = () => {
    const slugId = localStorage.getItem("selectedVenue") || defaultSlug;
    // dispatch(fetchMenuFeedApi({ priceRange, selectedBrand, selectedCategories, selectedType, sortOrder }))
    dispatch(fetchMenuFeedApi(slugId))
      .then((action) => {
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    const queryParams = new URLSearchParams();
    if (priceRange && priceRange.length > 0) {
      queryParams.set("priceRange", priceRange.join("-"));
    }
    if (selectedBrand?.length > 0) {
      queryParams.set("brand", selectedBrand);
    }
    if (selectedCategories?.length > 0) {
      queryParams.set("categories", selectedCategories);
    }
    if (selectedSubcategoryId) {
      queryParams.set("subcategories", selectedSubcategoryId);
    }
    if (selectedType) {
      queryParams.set("type", selectedType);
    }
    if (selectedTerpenes?.length > 0) {
      queryParams.set("terpene", selectedTerpenes);
    }
    if (selectedFlavors) {
      queryParams.set("flavor", selectedFlavors);
    }
    if (selectedEffects) {
      queryParams.set("effect", selectedEffects);
    }
    if (selectedStrains) {
      queryParams.set("strains", selectedStrains);
    }
    if (sortOrder) {
      queryParams.set("sortOrder", sortOrder);
    }

    navigate("/filterproduct", {
      state: {
        priceRange, selectedBrand, selectedCategories, selectedSubcategoryId, selectedType, selectedTerpenes, selectedFlavors, selectedEffects, selectedStrains, sortOrder, selectedWeight
      }
    });
    setFilterCriteriaChanged(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isFilterBoxOpen && !event.target.closest(".main_filter")) {
        setIsFilterBoxOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isFilterBoxOpen]);

  const handlePriceRangeChange = (value) => {
    dispatch(setPriceRange(value));
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
  };

  const handleCategoriesChange = (e, categoryId) => {
    const isChecked = e.target.checked;
    const updatedSelectedCategories = isChecked
      ? [...selectedCategories, categoryId] // Add category to selectedCategories
      : selectedCategories.filter((id) => id !== categoryId); // Remove category from selectedCategories

    dispatch(setSelectedCategories(updatedSelectedCategories));

    // Deselect subcategory if any category is unchecked
    if (!isChecked) {
      dispatch(setSelectedSubcategory([]));
    }
    e.stopPropagation();
  };

  const handleSubcategoryChange = (e, subcategoryId) => {
    const isChecked = e.target.checked;
    let updatedSelectedSubcategories = [...selectedSubcategoryId];

    if (isChecked) {
      updatedSelectedSubcategories.push(subcategoryId);
    } else {
      updatedSelectedSubcategories = updatedSelectedSubcategories.filter(id => id !== subcategoryId);
    }
    dispatch(setSelectedSubcategory(updatedSelectedSubcategories));
    e.stopPropagation();
  };

  const handleBrandChange = (e, brandId) => {
    const isChecked = e.target.checked;
    const currentSelectedBrands = [...selectedBrand];
    const updatedSelectedBrands = isChecked
      ? [...currentSelectedBrands, brandId]
      : currentSelectedBrands.filter((id) => id !== brandId);

    dispatch(setSelectedBrand(updatedSelectedBrands));
  };

  const handleTypeChange = (e, productTypeId) => {
    const isChecked = e.target.checked;
    const currentSelectedType = [...selectedType];
    const updatedSelectedtype = isChecked
      ? [...currentSelectedType, productTypeId]
      : currentSelectedType.filter((id) => id !== productTypeId);

    dispatch(setSelectedType(updatedSelectedtype));
  };

  const handleStrainsChange = (e, strainsId) => {
    const isChecked = e.target.checked;
    const currentSelectedStrain = [...selectedStrains];
    const updatedSelectedStrains = isChecked
      ? [...currentSelectedStrain, strainsId]
      : currentSelectedStrain.filter((id) => id !== strainsId);

    dispatch(setSelectedStrains(updatedSelectedStrains));
  };

  const handleEffectsChange = (e, effectsId) => {
    const isChecked = e.target.checked;
    const currentSelectedEffects = [...selectedEffects];
    const updatedSelectedEffects = isChecked
      ? [...currentSelectedEffects, effectsId]
      : currentSelectedEffects.filter((id) => id !== effectsId);

    dispatch(setSelectedEffects(updatedSelectedEffects));
  };

  const handleFlavorsChange = (e, flavorsId) => {
    const isChecked = e.target.checked;
    const currentSelectedFlavors = [...selectedFlavors];
    const updatedSelectedFlavors = isChecked
      ? [...currentSelectedFlavors, flavorsId]
      : currentSelectedFlavors.filter((id) => id !== flavorsId);

    dispatch(setSelectedFlavors(updatedSelectedFlavors));
  };

  const handleTerpenesChange = (e, terpenesId) => {
    const isChecked = e.target.checked;
    const currentSelectedTerpenes = [...selectedTerpenes];
    const updatedSelectedTerpenes = isChecked
      ? [...currentSelectedTerpenes, terpenesId]
      : currentSelectedTerpenes.filter((id) => id !== terpenesId);

    dispatch(setSelectedTerpenes(updatedSelectedTerpenes));
  };

  const handleWeightFilter = (e, weight) => {
    const isChecked = e.target.checked;
    let updatedSelectedWeights = [...selectedWeight]; // Make a copy of the selected weights array

    if (isChecked) {
      updatedSelectedWeights.push(weight); // Add the selected weight to the array
    } else {
      updatedSelectedWeights = updatedSelectedWeights.filter((w) => w !== weight); // Remove the unselected weight from the array
    }

    dispatch(setSelectedWeight(updatedSelectedWeights)); // Dispatch action to update selected weights
  };

  const filter_plus_icon = document.querySelectorAll('.filter_plus_icon');
  filter_plus_icon.forEach(element => {
    element.onclick = (elm) => {
      element.nextSibling.classList.toggle('d-block');
      element.nextSibling.classList.toggle('d-none');
      element.children[0].classList.toggle('minusIcon');

    }
  });

  const renderWeights = () => {
    return weights.map((weight) => (
      <div key={weight}>
        <input
          type="checkbox"
          id={`weight-${weight}`}
          value={weight}
          checked={selectedWeight?.includes(weight)}
          onChange={(e) => handleWeightFilter(e, weight)}
          onClick={(e) => e.stopPropagation()}
        />
        {" "}{weight} ({displayWeightsCount[weight] || 0})
      </div>
    ));
  };

  const renderCategories = () => {
    return sortedCategoriesNames.map((category) => (
      <div key={category.id}>
        <input
          type="checkbox"
          value={category.id}
          checked={selectedCategories?.includes(category.id) ?? false}
          onChange={(e) => handleCategoriesChange(e, category.id)}
          onClick={(e) => e.stopPropagation()}
        />
        {" "}{category.name} ({categoryProductCounts[category.id]})
        {category.subcategories.length > 0 && ( // Check if subcategories exist
          <span className="filter_plus_icon">
            <i className="fa fa-plus" onClick={(e) => e.stopPropagation()}></i>
          </span>
        )}
        <div className="plusTab d-none">
          {category.subcategories.map((subcategory) => (
            <div key={subcategory.id} className="pb-0">
              <input
                type="checkbox"
                value={subcategory.id}
                checked={selectedSubcategoryId?.includes(subcategory.id) ?? false}
                onChange={(e) => handleSubcategoryChange(e, subcategory.id)}
                onClick={(e) => e.stopPropagation()}
              />
              <span>{" "}{subcategory.name} ({subcategoryProductCounts[subcategory.id]}) </span>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const renderBrand = () => {
    return sortedBrandNames.map((brand) => (
      <div key={brand.id}>
        <input
          type="checkbox"
          value={brand.id}
          checked={selectedBrand?.includes(brand.id) ?? false}
          onChange={(e) => handleBrandChange(e, brand.id)}
          onClick={(e) => e.stopPropagation()}
        />
        {" "}{brand.name} ({brandProductCounts[brand.id]})
      </div>
    ));
  };
  const renderProductType = () => {
    return sortedTypesNames.map((ProductType) => (
      <div key={ProductType.id}>
        <input
          type="checkbox"
          value={ProductType.id}
          checked={selectedType?.includes(ProductType.id) ?? false}
          onChange={(e) => handleTypeChange(e, ProductType.id)}
          onClick={(e) => e.stopPropagation()}
        />
        {" "}{ProductType.name} ({typeProductCounts[ProductType.id]})
      </div>
    ));
  };
  const renderStrains = () => {
    return sortedStrainsNames.map((Strains) => (
      <div key={Strains.id}>
        <input
          type="checkbox"
          value={Strains.id}
          checked={selectedStrains?.includes(Strains.id) ?? false}
          onChange={(e) => handleStrainsChange(e, Strains.id)}
          onClick={(e) => e.stopPropagation()}
        />
        {" "}{Strains.name} ({strainProductCounts[Strains.id]})
      </div>
    ));
  };
  const renderTerpenes = () => {
    return sortedTerpenesNames.map((terpenes) => (
      <div key={terpenes.id}>
        <input
          type="checkbox"
          value={terpenes.id}
          checked={selectedTerpenes?.includes(terpenes.id) ?? false}
          onChange={(e) => handleTerpenesChange(e, terpenes.id)}
          onClick={(e) => e.stopPropagation()}
        />
        {" "}{terpenes.name} ({terpeneProductCounts[terpenes.id]})
      </div>
    ));
  };
  const renderFlavors = () => {
    return sortedFlavorsNames.map((flavors) => (
      <div key={flavors.id}>
        <input
          type="checkbox"
          value={flavors.id}
          checked={selectedFlavors?.includes(flavors.id) ?? false}
          onChange={(e) => handleFlavorsChange(e, flavors.id)}
          onClick={(e) => e.stopPropagation()}
        />
        {" "}{flavors.name} ({flavorsProductCounts[flavors.id]})
      </div>
    ));
  };
  const renderEffects = () => {
    return sortedEffectsNames.map((effect) => (
      <div key={effect.id}>
        <input
          type="checkbox"
          value={effect.id}
          checked={selectedEffects?.includes(effect.id) ?? false}
          onChange={(e) => handleEffectsChange(e, effect.id)}
          onClick={(e) => e.stopPropagation()}
        />{" "}{effect.name} ({effectsProductCounts[effect.id]})
      </div>
    ));
  };

  return (
    <>
      <div className="search_filter_box">
        <Search setIsActive={setIsActive} handleClick={handleClick} />
        <div className="main_filter" onClick={handleFilterBoxToggle}>
          <i className="fa fa-sliders"></i>
          <div className={`filter_box ${isFilterBoxOpen ? "open" : ""}`} onClick={(e) => e.stopPropagation()}>
            <p>{Constant.PRICE_RANGE}</p>
            <fieldset className="form-group" onClick={(e) => e.stopPropagation()}>
              <div className="rangeSlider d-flex">
                <Slider
                  range
                  min={0}
                  max={200}
                  value={[minPrice, maxPrice]}
                  onChange={handlePriceRangeChange}
                />
              </div>
              <div className="rsMinMax d-flex">
                <span>{Constant.DOLLAR}{priceRange[0]}</span>
                <span>{Constant.DOLLAR}{priceRange[1]}</span>
              </div>
            </fieldset>
            <div className="d-flex">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header onClick={(e) => e.stopPropagation()}> {Constant.SELECT_CATEGORY} </Accordion.Header>
                  <Accordion.Body>
                    {renderCategories()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header onClick={(e) => e.stopPropagation()}>{Constant.SELECT_BRAND}</Accordion.Header>
                  <Accordion.Body>
                    {renderBrand()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={(e) => e.stopPropagation()}>{Constant.SELECT_TYPE}</Accordion.Header>
                  <Accordion.Body>
                    {renderProductType()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header onClick={(e) => e.stopPropagation()}>{Constant.SELECT_STRAINS}</Accordion.Header>
                  <Accordion.Body>
                    {renderStrains()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header onClick={(e) => e.stopPropagation()}>{Constant.SELECT_TERPENES}</Accordion.Header>
                  <Accordion.Body>
                    {renderTerpenes()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header onClick={(e) => e.stopPropagation()}>{Constant.SELECT_FLAVORS}</Accordion.Header>
                  <Accordion.Body>
                    {renderFlavors()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header onClick={(e) => e.stopPropagation()}>{Constant.SELECT_EFFECTS}</Accordion.Header>
                  <Accordion.Body>
                    {renderEffects()}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header onClick={(e) => e.stopPropagation()}>Weights</Accordion.Header>
                  <Accordion.Body>
                    {renderWeights()}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

            </div>

            <div className="apply_btn">
              <button type="button" className="btn" onClick={handleApplyFilters}>
                {Constant.APPLY_FILTER}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mobileMenu">
        {!showList ?
          <div onClick={() => { setShowList(!showList) }}><i class="fa fa-times" aria-hidden="true"></i></div>
          :
          <div onClick={() => { setShowList(!showList) }}><i class="fa fa-bars" aria-hidden="true"></i></div>
        }
      </div>
      {!showList ?

        <div className={isActive ? 'user_account aa' : 'user_acount'}>

          <span className="position-relative" ref={modalRef}>
            <MiniCart showModal={showModal} setShowModal={setShowModal} />
            <button className="cartbuttton"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {items ? items.length : 0}
              </span>
              <i className="fa fa-cart-shopping"></i>
            </button>
          </span>
        </div>
        : null}
    </>
  );
};

export default NavBar;
