import React from "react";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import { Outlet } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

function App() {
  return (
    <>
     <ScrollToTop smooth color="#295c53" />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;