import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleMedicalOnly, selectIsMedicalOnly } from "../store/slice/MenuFeedAPISlice";
import { useLocation, useParams } from "react-router-dom";

const MenuType = () => {
  const dispatch = useDispatch();
  const isMedicalOnly = useSelector(selectIsMedicalOnly);
  const location = useLocation();
  const { categoryId } = useParams();
  const isHomepage = location.pathname === "/";
  const isCategoryPage = location.pathname.startsWith("/category/"); // Check if it's a category page
  const isFilterPage = location.pathname === "/filterproduct"; // Check if it's a filter page
  const isSearchPage = location.pathname === "/products"; // Check if it's a search page


  useEffect(() => {
    const storedMenuType = localStorage.getItem("menuType");
    if (storedMenuType) {
      dispatch(toggleMedicalOnly(storedMenuType === "Medical"));
    } else {
      // Default to Recreational if nothing is stored
      dispatch(toggleMedicalOnly(false));
      localStorage.setItem("menuType", "Recreational");
    }
  }, [dispatch]);

  const handleMenuTypeChange = (e) => {
    const selectedMenuType = e.target.value;
    localStorage.setItem("menuType", selectedMenuType);
    if (selectedMenuType === "Medical") {
      dispatch(toggleMedicalOnly(true)); // Set filter to true for Medical
    } else {
      dispatch(toggleMedicalOnly(false)); // Set filter to false for Recreational
    }
  };

  return (
    <span className="address">
      <i className="fa fa-file-invoice"></i>
      {(isHomepage || isCategoryPage || isFilterPage || isSearchPage) ? (
        <select className="location_pickup" onChange={handleMenuTypeChange} value={localStorage.getItem("menuType")}>
          <option value="Recreational" className="text-dark">
            <span className="location">Recreational</span>
          </option>
          <option value="Medical" className="text-dark">
            Medical
          </option>
        </select>
      ) : (
        // Render static text on other pages
        <span className="space"> {localStorage.getItem("menuType") === "Medical" ? "Medical" : "Recreational"}</span>
      )}
    </span>
  );
};

export default MenuType;