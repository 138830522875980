import { createSlice } from "@reduxjs/toolkit";
//  import { putQuotesUpdateItemsApi } from '../PutQuotesUpdateAction';
import { userInfoData } from "../UserInfoAction";

const UserinfoSlice = createSlice({
  name: "userInfo",
  initialState: {
    info: null,
    loading: false,
    error: null,
  },
  reducers: {
    emptyUserinfoSlice:(state, action) => {
      state.info = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userInfoData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userInfoData.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(userInfoData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const {emptyUserinfoSlice} = UserinfoSlice.actions
export default UserinfoSlice.reducer;