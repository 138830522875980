import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMerchantInfo } from "../store/actions";
import Logo from "./Logo";
import StoreOpenTime from "./StoreOpenTime";
import StoreLocation from "./StoreLocation";
import MenuType from "./MenuType";
import * as Constant from "../Constants";
import { emptyInitializeQuotes } from "../store/slice/InitializeQuotesAPISlice";

const MainHeader = () => {
  const dispatch = useDispatch();
  const [selectedVenueLogo, setSelectedVenueLogo] = useState(null);
  const [selectedVenueSlug, setSelectedVenueSlug] = useState(null);

  // Fetch merchant info when the component mounts
  useEffect(() => {
    dispatch(fetchMerchantInfo());
  }, [dispatch]);

  const info = useSelector((state) => state.merchant?.info);

  const handleVenueChange = (selectedVenueSlug) => {
    setSelectedVenueSlug(selectedVenueSlug);
    dispatch(emptyInitializeQuotes());

    const selectedVenue = info?.merchant?.venues.find(
      (venue) => venue.slug === selectedVenueSlug
    );
    const merchantLogoUrl = info?.merchant?.logo?.url;
    // Check if selectedVenue and selectedVenue.logo are defined before accessing the URL
    const venueLargeLogoUrl =
      selectedVenue?.logo?.medium?.url || merchantLogoUrl || null;
    setSelectedVenueLogo(venueLargeLogoUrl);
  };

  if (!info) {
    return <div>{Constant.LOADING}</div>; // Handle loading state if info is not available yet
  }

  return (
    <>
      <Logo logoUrl={selectedVenueLogo} />
      <div className="menu_item">
        <div className="location_pickup">
          <StoreLocation onVenueChange={handleVenueChange} />
          <MenuType />
        </div>
        <StoreOpenTime />
      </div>
    </>
  );
};

export default MainHeader;


