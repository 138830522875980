import { createAsyncThunk } from "@reduxjs/toolkit";

const createHeaders = () => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("MerchantToken", window.posabitmenuwidget.config.api_key);
  return headers;
};

export const postInitializeQuotesApi = createAsyncThunk("initializeQuotes", async ( payload ) => {
  
  try {
    let myHeaders = createHeaders();
    let raw = JSON.stringify(payload);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://qa-ecomm-app.posabit.com/mcx/oregon_tops/venue/${payload.slug}/v1/quotes`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
});

export const putQuotesUpdateItemsApi = createAsyncThunk("initializeQuotes", async (payload) => {
 
  try {
    let myHeaders = createHeaders();
    let raw = JSON.stringify(payload?.quote || {});

    const apiUrl = `https://qa-ecomm-app.posabit.com/mcx/oregon_tops/venue/${payload.slug}/v1/quotes/${payload?.reference_no}/items`;

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(apiUrl, requestOptions);

    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
});

