import { createAsyncThunk } from "@reduxjs/toolkit";

const createHeaders = () => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("MerchantToken", window.posabitmenuwidget.config.api_key);
  return headers;
};
export const userInfoData = createAsyncThunk("userInfo", async (payload) => {
  try {
    let myHeaders = createHeaders();
    let raw = JSON.stringify(payload);

    console.log(payload?.reference_no, "payload1");
    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://qa-ecomm-app.posabit.com/mcx/oregon_tops/venue/${payload?.slug}/v1/quotes/${payload?.reference_no}`,

      requestOptions
    );
    if (!response.ok) {
      throw new Error("Failed to fetch user info");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
});


