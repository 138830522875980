import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";


const persistConfig = {
  key: "root",
  storage,
}

// const persistedReducer = persistReducer(persistConfig, cartSlice)
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer:persistedReducer,
	
  // reducer: {
  // 	filter: filterReducer,
  // 	// reducer: rootReducer,
  // 	// merchant: merchantReducer,
  // 	// menuFeed: MenuFeedAPISlice,
  // 	// cart:cartSlice,
  // 	reducer: persistedReducer,
  //  	middleware: [thunkMiddleware],
  // },
});

export const persistor = persistStore(store);