import { combineReducers } from '@reduxjs/toolkit';
import merchantReducer from './slice/merchantSlice';
import MenuFeedAPISlice from './slice/MenuFeedAPISlice';
import InitialQuotesAPISlice from './slice/InitializeQuotesAPISlice'
import {cartReducer} from './slice/cartSlice';
import wishlistReducer from './slice/wishlistSlice';
import PutQuotesUpdateSlice from './slice/PutQuotesUpdateSlice';
import GetCartInfoSlice from "./slice/GetQuoteInfoSlice"
import UserInfoSlice from './slice/UserInfoSlice';
import venueReducer from "./slice/venueSlice";
import SubmitOrderSlice from "./slice/SubmitOrderSlice";
import ContentSlice from "./slice/ContentSlice";
import SdpData from "./slice/sdpSlice";
import DiscountData from "./slice/discountSlice";

const rootReducer = combineReducers({
  merchant: merchantReducer,
  menuFeed:MenuFeedAPISlice,
  cart:cartReducer,
  wishlist: wishlistReducer,
  initialQuotes:InitialQuotesAPISlice,
  putUpdateSlice:PutQuotesUpdateSlice,
  getCartInfoData:GetCartInfoSlice,
  userInfoSlice:UserInfoSlice,
  venue: venueReducer,
  submitOrderRecord: SubmitOrderSlice,
  content: ContentSlice,
  sdpData:SdpData,
  discountData:DiscountData,
  
});

export default rootReducer;