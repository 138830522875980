
import React,{useState} from 'react';
import * as Constant from "../Constants";

const TopBar = () => {
  const [showTopBar,setShowTopBar] = useState(true)
  return (
    <>
    {showTopBar?<div className="top_header">
      <p>{Constant.NOW_ACCEPTING}</p>
      <i class="fa fa-close" onClick={()=>setShowTopBar(!showTopBar)}></i>
    </div>:null}
    </>
  )
}

export default TopBar;

