import React, { useEffect } from "react";
import ProductSlider from "./ProductSlider";
import { useSelector, useDispatch } from "react-redux";
import { fetchMenuFeedApi } from "../store/MenuFeedaction";
import { Link } from 'react-router-dom';
import * as Constant from "../Constants";

const CategoryWithProduct = (props) => {

  const dispatch = useDispatch();
  const { info, loading, error } = useSelector((state) => state.menuFeed || {});
  const isMedicalOnly = localStorage.getItem("menuType") === "Recreational" ? false : true
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlugId = slugId;
  useEffect(() => {
    const storedSlugId = localStorage.getItem("selectedVenue");
    const slugId = storedSlugId || defaultSlugId;

    // Fetch data with the retrieved or default slugId
    dispatch(fetchMenuFeedApi(slugId));
  }, [dispatch, isMedicalOnly]);

  if (loading) {
    return <img className="mx-auto d-block" src="./images/loading.gif" alt="Loading..." />;
  }

  if (error) {
    return <div>{Constant.ERROR} {error}</div>;
  }

  const categoriesWithProducts = info?.product_data?.categories?.filter((category) => {
    const productsInCategory = info?.product_data?.menu_items?.filter((product) => product.category_ids.includes(category.id));
    return (isMedicalOnly ? productsInCategory.some(product => product.is_medical_only) : productsInCategory.length > 0);
  });
  const products = info?.product_data?.menu_items || [];
  const product_id = products.map((product) => product.product_id);
  
  
  return (
    <section id="product_section">
      <div className="container-fluid ">
        {categoriesWithProducts?.length > 0 ? (
          categoriesWithProducts?.map((item, index) => (
            <React.Fragment key={index}>
              <div className="row">
                <div className="col-6 deals">
                  <p>{item.name}</p>
                </div>
                <div className="col-6 text-end View_all">
                  <Link to={`/category/${item?.id}`} state={{ product_id: product_id }}>
                    {Constant.VIEW_ALL}
                  </Link>

                </div>
              </div>
              <ProductSlider products={info?.product_data?.menu_items} ids={item?.id} discount={item?.discounts?.id} dis={info?.product_data.discounts[0]?.item_display_name} />
            </React.Fragment>
          ))
        ) : (
          <h2>{Constant.NO_PRODUCTS_FOUND}</h2>
        )}
      </div>
    </section>
  );
};

export default CategoryWithProduct;
