import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userInfoData } from "../store/UserInfoAction";
import * as Constant from "../Constants";

const UserInformation = (props) => {
  const dispatch = useDispatch();

  const formData = useSelector(
    (store) => store?.userInfoSlice?.info?.quote?.customer
  );

  {
    const [inputValues, setInputValue] = useState({
      fName: formData?.first_name,
      lastName: formData?.last_name,
      email: formData?.email,
      phone: formData?.telephone,
    });

    const [validation, setValidation] = useState({
      fName: "",
      lastName: "",
      email: "",
      phone: "",
    });

    useEffect(() => {
      localStorage.setItem("inputValues", JSON.stringify(inputValues));
    }, [inputValues]);

    //handle submit updates

    function handleChange(event) {
      const { name, value } = event.target;
      let formattedValue = value;
      if (name === "fName") {
        formattedValue = value.replace(/[^a-zA-Z]/g, "");
      }
      if (name === "lastName") {
        formattedValue = value.replace(/[^a-zA-Z]/g, "");
      }

      if (name === "phone") {
        const cleanedNumber = value.replace(/\D/g, "");
        const trimmedNumber = cleanedNumber.slice(0, 10); // Restrict to 10 digits
        if (trimmedNumber.length === 10) {
          const match = trimmedNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            formattedValue = `(${match[1]}) ${match[2]}-${match[3]}`;
          }
        }
        checkValidation(name, formattedValue, trimmedNumber); // Pass trimmedNumber as an argument
      } else {
        checkValidation(name, formattedValue);
      }

      setInputValue({ ...inputValues, [name]: formattedValue });
    }

    const checkValidation = (name, value, trimmedNumber) => {
      const emailCond =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      const phoneCond =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

      switch (name) {
        case "fName":
          setValidation({
            ...validation,
            fName: value ? "" : "First name is required",
          });
          break;

        case "lastName":
          setValidation({
            ...validation,
            lastName: value ? "" : "Last name is required",
          });
          break;

        case "email":
          setValidation({
            ...validation,
            email: value
              ? value?.match(emailCond)
                ? ""
                : "Please Enter a valid email address"
              : "Email is required",
          });
          break;

        case "phone":
          setValidation({
            ...validation,
            phone: value
              ? trimmedNumber && trimmedNumber.match(phoneCond) // Add null check
                ? ""
                : "Please Enter a valid Phone Number"
              : "Phone Number is required",
          });
          break;

        case "All":
          setValidation({
            ...validation,
            fName: inputValues?.fName ? "" : "First name is required",
            lastName: inputValues?.lastName ? "" : "Last name is required",
            email: inputValues?.email ? "" : "Email is required",
            phone: inputValues?.phone ? "" : "Phone Number is required",
          });
          break;

        default:
          setValidation({
            fName: "",
            lastName: "",
            email: "",
            phone: "",
          });
          break;
      }
    };

    const slugId = useSelector(
      (store) =>
        store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
          ?.slug
    );
    const defaultSlug = slugId;

    const reference_no = useSelector(
      (store) => store?.initialQuotes?.info?.quote?.reference_no
    );

    const handleSubmit = (e) => {
      e.preventDefault();

      if (
        inputValues?.fName &&
        inputValues?.lastName &&
        inputValues?.email &&
        inputValues?.phone
      ) {
        if (reference_no) {
          const slugId = localStorage.getItem("selectedVenue") || defaultSlug;
          dispatch(
            userInfoData({
              slug: slugId,
              reference_no,

              quote: {
                customer: {
                  first_name: inputValues?.fName,
                  last_name: inputValues?.lastName,
                  email: inputValues?.email,
                  telephone: inputValues?.phone,
                },
              },
            })
          );
        }
        props.handleClick(2);
      } else {
        checkValidation("All");
      }
    };

    return (
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="col-md-12">
            <div className="info_box">
              <div className="row">
                <div class="col-md-3 mb-3">
                  <input
                    placeholder="First Name"
                    type="string"
                    name="fName"
                    id="fName"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                    value={inputValues.fName}
                    // required
                  />
                  {validation.fName && (
                    <div className="validation_color">{validation.fName}</div>
                  )}
                </div>

                <div class="col-md-3 mb-3">
                  <input
                    placeholder="Last Name"
                    type="string"
                    name="lastName"
                    id="lastName"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                    value={inputValues.lastName}
                    // required
                  />
                  {validation.lastName && (
                    <div className="validation_color">
                      {validation.lastName}
                    </div>
                  )}
                </div>

                <div class="col-md-3 mb-3">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                    value={inputValues.email}
                    // required
                  />
                  {validation.email && (
                    <div className="validation_color">{validation.email}</div>
                  )}
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone No."
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                    value={inputValues.phone}
                  />
                  {validation.phone && (
                    <div className="validation_color">{validation.phone}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <button
                className="control-btn paymentbtn"
                type="submit"
                value="submit"
                //  onClick={handle}
              >
                {Constant.CONTINUE}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
};
export default UserInformation;