import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { selectMenuFeed } from "../store/slice/MenuFeedAPISlice";
import { fetchMenuFeedApi } from "../store/MenuFeedaction";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Constant from "../Constants";
import slugify from "react-slugify";
import {
  incrementQuantity,
  decrementQuantity,
  changeVariant,
} from "../store/slice/MenuFeedAPISlice";
import {
  postInitializeQuotesApi,
  putQuotesUpdateItemsApi,
} from "../store/InitializeQuotesPostAPI";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchBrandId, fetchProductId } from "../utility/productUtils.js";
import { getSdpData } from "../store/SdpAction.js";
import { primaryColor } from '../store/slice/ContentSlice.js';
import { sliderSettings} from "../utility/sliderUtils.js";
const ProductDetail = (props) => {
  // Update the dynamic color value in CSS custom property
  const primaryColorValue = useSelector(primaryColor);
  document.documentElement.style.setProperty('--dynamic-color', primaryColorValue);
  const [activeProductCard, setActiveProductCard] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabledButtons, setDisabledButtons] = useState({});
  const menuFeedData = useSelector(selectMenuFeed);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [selectedProductVariant, setSelectedProductVariant] = useState({});

  const [selectedWeight, setSelectedWeight] = useState("1.0 grams");
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedSDPCategories, setRelatedSDPCategories] = useState([]);
  const [currentProSelectedVariant, setCurrentProSelectedVariant] = useState(
    {}
  );
  const [currentProSelectedVariants, setCurrentProSelectedVariants] = useState(
    {}
  );

  const location = useLocation();
  let { brands, products, product_id, sdp_category } = location.state || {};
  if (brands === undefined || product_id === undefined) {
    const urlArr = location.pathname.split("/");
    const brandName = urlArr[1];
    const productNameWithSlug = urlArr[2];

    brands = fetchBrandId(brandName, menuFeedData);
    product_id = fetchProductId(productNameWithSlug, menuFeedData);
  }

  useEffect(() => {
    setCurrentProSelectedVariant(
      menuFeedData?.product_data?.menu_items
        ?.filter((item) => {
          return item.product_id == product_id;
        })[0]
        .variants?.filter((va) => va?.selected)[0]
    );
  }, [menuFeedData]);

  const handleVariantChange = (e, pId) => {
    dispatch(changeVariant({ productId: pId, variantId: e.target.value }));
  };

  const selectedVenueSlug = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlugId = selectedVenueSlug;
  // Fetch SDP data info when the component mounts
  useEffect(() => {
    const storedSlugId = localStorage.getItem("selectedVenue");
    const slugId = storedSlugId || defaultSlugId;
    dispatch(getSdpData(slugId));
  }, [dispatch]);

  // End Here

  useEffect(() => {
    const storedSlugId = localStorage.getItem("selectedVenue");
    const slugId = storedSlugId || defaultSlugId;
    dispatch(fetchMenuFeedApi(slugId));
  }, [dispatch]);

  // Brand Related Products
  useEffect(() => {
    if (menuFeedData && selectedProduct) {
      const selectedProductId = selectedProduct[0]?.product_id;
      const selectedProductBrandId = selectedProduct[0]?.brand_id;

      const filteredRelatedProducts =
        menuFeedData?.product_data?.menu_items?.filter(
          (item) =>
            item.product_id !== selectedProductId &&
            item.brand_id === selectedProductBrandId
        ) || [];

      setRelatedProducts(filteredRelatedProducts);
    }
  }, [menuFeedData, selectedProduct]);
  // End Brand Related Products
   // SDP Category Related Products
   useEffect(() => {
    if (!menuFeedData || !selectedProduct) {
      return;
    }
  
    const selectedProductId = selectedProduct[0]?.product_id;
    const selectedProductSdp = selectedProduct[0]?.sdp_category;
  
    const filteredRelatedSdpProducts =
      menuFeedData?.product_data?.menu_items?.filter(
        (item) =>
          item.product_id !== selectedProductId &&
          item.sdp_category === String(selectedProductSdp)
      ) || [];
  
    setRelatedSDPCategories(filteredRelatedSdpProducts);
  }, [menuFeedData, selectedProduct]);  
  
  // End SDP Category Related Products

  useEffect(() => {
    if (menuFeedData) {
      setSelectedProduct(
        menuFeedData?.product_data?.menu_items?.filter((item) => {
          return item.product_id == product_id;
        })
      );

      let defaultVarient = menuFeedData?.product_data?.menu_items?.filter(
        (item) => {
          return item.product_id == product_id;
        }
      )[0]?.variants[0];

      setSelectedWeight(`${defaultVarient?.unit} ${defaultVarient?.unit_type}`);
    }
  }, [menuFeedData, brands, products, product_id, sdp_category]);
  // Wishlist product

  //SDP Data

  const sdpData = useSelector((store) => store?.sdpData?.info?.categories);
  const sdp_logo = useSelector((store) => store?.sdpData?.info?.sdp_logo);
  const iron_stem_logo = useSelector(
    (store) => store?.sdpData?.info?.iron_stem_logo
  );

  //End SDP Data

  // Call this function to get the price based on the selected weight
  const displayPrice1 = (item) => {
    const variant = item?.variants[0];

    if (variant) {
      const regularPrice = variant.price_cents
        ? variant.price_cents / 100
        : null;
      const discountedPrice = variant.discounted_price_cents
        ? variant.discounted_price_cents / 100
        : null;

      const regularMedPrice = variant.med_price_cents
        ? variant.med_price_cents / 100
        : null;
      const discountedMedPrice = variant.discounted_med_price_cents
        ? variant.discounted_med_price_cents / 100
        : null;

      if (regularMedPrice !== null && discountedMedPrice !== null) {
        // Product has both regular and discounted prices
        return (
          <div>
            <strike>${regularMedPrice.toFixed(2)}</strike>
            <span className="price">
              &nbsp;&nbsp;${discountedMedPrice.toFixed(2)}
            </span>
          </div>
        );
      } else if (regularPrice !== null && discountedPrice !== null) {
        // Product has both regular/medical and discounted prices
        return (
          <div>
            <strike>${regularPrice.toFixed(2)}</strike>
            <span className="price">
              &nbsp;&nbsp;${discountedPrice.toFixed(2)}
            </span>
          </div>
        );
      } else if (regularMedPrice !== null) {
        // Product has regular/medical price only
        return `$${regularMedPrice.toFixed(2)}`;
      } else if (regularPrice !== null) {
        // Product has regular price only
        return `$${regularPrice.toFixed(2)}`;
      } else {
        return "N/A";
      }
    }

    return "N/A";
  };

  const reference_no = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.reference_no
  );

  const items = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.items
  );

  const addToCartItem = async (item) => {
    // Disable the button
    setDisabledButtons((prevDisabledButtons) => ({
      ...prevDisabledButtons,
      [item.id]: true,
    }));
    try {
      const slugId = localStorage.getItem("selectedVenue") || defaultSlugId;
      const existingCartItem = items?.find(
        (it) =>
          it.sku_id === item?.sku_id &&
          it.unit === currentProSelectedVariant?.unit &&
          it.unit_type === currentProSelectedVariant?.unit_type
      );

      if (
        existingCartItem &&
        existingCartItem.quantity === currentProSelectedVariant?.quantity_on_hand
      ) {
        toast.warning("You have reached the maximum quantity");
      } else {
        if (reference_no) {
          const found = items.filter((it) => {
            if (
              it.sku_id === item?.sku_id &&
              it.unit === currentProSelectedVariant?.unit &&
              it.unit_type === currentProSelectedVariant?.unit_type
            ) {
              return it;
            }
          });
          if (found.length > 0) {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    ...items.map((it) => {
                      if (
                        it.sku_id === item?.sku_id &&
                        it.unit === currentProSelectedVariant?.unit &&
                        it.unit_type === currentProSelectedVariant?.unit_type
                      ) {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity:
                            it?.quantity + currentProSelectedVariant?.quantity >
                              currentProSelectedVariant?.quantity_on_hand
                              ? currentProSelectedVariant?.quantity_on_hand
                              : it?.quantity + currentProSelectedVariant?.quantity,
                        };
                      } else {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity: it?.quantity,
                        };
                      }
                    }),
                  ],
                },
              })
            );
            toast.success("Product quantity updated in cart");
          } else {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    {
                      sku_id: item?.sku_id,
                      unit: currentProSelectedVariant?.unit,
                      unit_type: currentProSelectedVariant?.unit_type,
                      quantity: currentProSelectedVariant?.quantity,
                    },
                    ...items.map((it) => {
                      return {
                        sku_id: it?.sku_id,
                        unit: it?.unit,
                        unit_type: it?.unit_type,
                        quantity: it?.quantity,
                      };
                    }),
                  ],
                },
              })
            );
            toast.success("Product Added to cart");
          }
        } else {
          dispatch(
            postInitializeQuotesApi({
              slug: slugId,
              quote: {
                items: [
                  {
                    sku_id: item?.sku_id,
                    unit: currentProSelectedVariant?.unit,
                    unit_type: currentProSelectedVariant?.unit_type,
                    quantity: currentProSelectedVariant?.quantity,
                  },
                ],
              },
            })
          );
          toast.success("Product Added to cart");
        }
      }
      setTimeout(() => {
        setDisabledButtons((prevDisabledButtons) => ({
          ...prevDisabledButtons,
          [item.id]: false,
        }));
      }, 1500);
    } catch (error) {
      // Handle error
      setDisabledButtons((prevDisabledButtons) => ({
        ...prevDisabledButtons,
        [item.id]: false,
      }));
    }
  };

  const incrementQuantityHandler = () => {
    if (currentProSelectedVariant?.quantity_on_hand > 0) {
      if (
        currentProSelectedVariant?.quantity <
        currentProSelectedVariant?.quantity_on_hand
      ) {
        dispatch(incrementQuantity({ variant: currentProSelectedVariant }));
      } else {
        toast.warning("No more quantity in stock");
      }
    }
  };

  const decrementQuantityHandler = () => {
    if (currentProSelectedVariant?.quantity > 1) {
      dispatch(decrementQuantity({ variant: currentProSelectedVariant }));
    } else {
      toast.warning("Minimum 1 quantity required");
    }
  };

  // Function to retrieve names of terpenes, effects, flavors based on their IDs
  const getNamesFromIds = (ids, data) => {
    return ids.map((id) => {
      const foundItem = data.find((item) => item.id === id);
      return foundItem ? foundItem.name : ''; // Return name if found, empty string otherwise
    });
  };
  const getColorFromIds = (ids, data) => {
    return ids.map((id) => {
      const foundItem = data.find((item) => item.id === id);
      return foundItem ? foundItem.color : ''; // Return color if found, empty string otherwise
    });
  };

  //For Related product
  // Variant chnage
  useEffect(() => {
    if (Object.keys(selectedProducts).length !== 0) {
      setCurrentProSelectedVariants(
        menuFeedData?.product_data?.menu_items
          ?.filter((item) => {
            if (item.product_id == selectedProducts.product_id) {
              return item;
            }
          })?.[0]
          .variants?.filter((va) => va?.selected)[0]
      );
    }
  }, [menuFeedData]);

  const handleVariantChanges = (e) => {
    setSelectedProductVariant(
      selectedProducts.variants.filter((vr) => {
        if (vr.id === e.target.value) {
          return vr;
        }
      })?.[0]
    );
    // dispatch(changeVariant({ productId: pId, variantId: e.target.value }));
  };

  /* Add to cart */

  const addToCartitems = async (selectedProducts) => {
    // Disable the button
    setDisabledButtons((prevDisabledButtons) => ({
      ...prevDisabledButtons,
      [selectedProducts.id]: true,
    }));
    try {
      const selectedVenueSlug = localStorage.getItem("selectedVenue") || defaultSlugId;
      const existingCartItem = items?.find(
        (it) =>
          it.sku_id === selectedProducts?.sku_id &&
          it.unit === selectedProductVariant?.unit &&
          it.unit_type === selectedProductVariant?.unit_type
      );

      if (
        existingCartItem &&
        existingCartItem.quantity === selectedProductVariant?.quantity_on_hand
      ) {
        toast.warning("You have reached the maximum quantity");
      } else {
        if (reference_no) {
          const found = items.filter((it) => {
            if (
              it.sku_id === selectedProducts?.sku_id &&
              it.unit === selectedProductVariant?.unit &&
              it.unit_type === selectedProductVariant?.unit_type
            ) {
              return it;
            }
          });
          if (found.length > 0) {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: selectedVenueSlug,
                reference_no,
                quote: {
                  items: [
                    ...items.map((it) => {
                      if (
                        it.sku_id === selectedProducts?.sku_id &&
                        it.unit === selectedProductVariant?.unit &&
                        it.unit_type === selectedProductVariant?.unit_type
                      ) {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity:
                            it?.quantity + selectedProductVariant?.quantity >
                              selectedProductVariant?.quantity_on_hand
                              ? selectedProductVariant?.quantity_on_hand
                              : it?.quantity + selectedProductVariant?.quantity,
                        };
                      } else {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity: it?.quantity,
                        };
                      }
                    }),
                  ],
                },
              })
            );
            toast.success("Product quantity updated in cart");
          } else {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: selectedVenueSlug,
                reference_no,
                quote: {
                  items: [
                    {
                      sku_id: selectedProducts?.sku_id,
                      unit: selectedProductVariant?.unit,
                      unit_type: selectedProductVariant?.unit_type,
                      quantity: selectedProductVariant?.quantity,
                    },
                    ...items.map((it) => {
                      return {
                        sku_id: it?.sku_id,
                        unit: it?.unit,
                        unit_type: it?.unit_type,
                        quantity: it?.quantity,
                      };
                    }),
                  ],
                },
              })
            );
            toast.success("Product Added to cart");
          }
        } else {
          dispatch(
            postInitializeQuotesApi({
              slug: selectedVenueSlug,
              quote: {
                items: [
                  {
                    sku_id: selectedProducts?.sku_id,
                    unit: selectedProductVariant?.unit,
                    unit_type: selectedProductVariant?.unit_type,
                    quantity: selectedProductVariant?.quantity,
                  },
                ],
              },
            })
          );
          toast.success("Product Added to cart");
        }
      }
      setTimeout(() => {
        setDisabledButtons((prevDisabledButtons) => ({
          ...prevDisabledButtons,
          [selectedProducts.id]: false,
        }));
      }, 1500);
    } catch (error) {
      // Handle error
      setDisabledButtons((prevDisabledButtons) => ({
        ...prevDisabledButtons,
        [selectedProducts.id]: false,
      }));
    }
  };
  /* End Add to cart */
  //render price
  const renderPrice = () => {
    let price = 0;

    if (
      selectedProductVariant.med_price_cents ||
      selectedProductVariant.discounted_med_price_cents
    ) {
      price =
        selectedProductVariant.med_price_cents ||
        selectedProductVariant.discounted_med_price_cents;
    } else {
      price =
        selectedProductVariant.discounted_price_cents ||
        selectedProductVariant.price_cents;
    }

    // Assuming the quantity is stored in a variable called 'quantity'
    const totalPrice = (price / 100) * selectedProductVariant?.quantity;

    // Return the formatted price
    return totalPrice.toFixed(2); // Assuming you want to display the price with 2 decimal places
  };

  const incrementQuantityHandlers = () => {
    if (selectedProductVariant?.quantity_on_hand > 0) {
      if (
        selectedProductVariant?.quantity <
        selectedProductVariant?.quantity_on_hand
      ) {
        // dispatch(incrementQuantity({ variant: selectedProductVariant }));
        setSelectedProductVariant({
          ...selectedProductVariant,
          quantity: selectedProductVariant?.quantity + 1,
        });
      } else {
        toast.warning("No more quantity in stock");
      }
    }
  };

  const decrementQuantityHandlers = () => {
    if (selectedProductVariant?.quantity > 1) {
      // dispatch(decrementQuantity({ variant: selectedProductVariant }));
      setSelectedProductVariant({
        ...selectedProductVariant,
        quantity: selectedProductVariant?.quantity - 1,
      });
    } else {
      toast.warning("Minimum 1 quantity required");
    }
  };

  const productCard = document.querySelectorAll(
    ".product_cart:has(.addtocart_btn)"
  );
  const addCartBtn = document.querySelectorAll(".product_cart .addtocart_btn");
  const closeActive = document.querySelectorAll(
    ".product_cart:has(.addtocart_btn) .closeActive"
  );

  addCartBtn.forEach((e, ind) => {
    e.onclick = () => {
      if (activeProductCard !== productCard[ind]) {
        if (activeProductCard) {
          activeProductCard.classList.remove("active");
        }
        setActiveProductCard(productCard[ind]);
        productCard[ind].classList.add("active");
      }
    };
  });

  closeActive.forEach((e, ind) => {
    e.onclick = () => {
      productCard[ind].classList.remove("active");
      setActiveProductCard(null);
    };
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeProductCard && !activeProductCard.contains(event.target)) {
        activeProductCard.classList.remove("active");
        setActiveProductCard(null);
      }
    };

    const handleBodyClick = (event) => {
      handleClickOutside(event);
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [activeProductCard]);

  // End for related product

  if (!selectedProduct) {
    return <div>{Constant.LOADING}</div>;
  }
 
  return (
    <section className="breadcrumb mt-4">
      <div className="container product-info">
        {selectedProduct?.map((item) => {

          const discountIdForMedPrice =
            item?.variants[0]?.discount_id_for_med_price;
          const discountIdForPrice = item?.variants[0]?.discount_id_for_price;

          const discountForMedPrice =
            menuFeedData?.product_data?.discounts.find(
              (d) => d.id === discountIdForMedPrice
            );
          const discountForPrice = menuFeedData?.product_data?.discounts.find(
            (d) => d.id === discountIdForPrice
          );

          const discountNameForMedPrice = discountForMedPrice?.item_display_name;
          const discountNameForPrice = discountForPrice?.item_display_name;

          const chosenDiscountName =
            discountNameForMedPrice || discountNameForPrice;

          const sdpCategoryDescription = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.description;
          const sdpCategoryIcon = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.icon_url;
          
          const sdpnearestCategories = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.nearest_sdp_category;
          const sdpCategoryName = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.name;
          const sdpCategoryColor = sdpData?.find(
            (category) => category?.sdp_category === item?.sdp_category
          )?.color;

          const sdpclosetCategoryColor = sdpData?.find(
            (category) => category?.sdp_category === sdpnearestCategories
          )?.color;

          const style1 = {
            backgroundColor: sdpCategoryColor,

          };
          const style = {
            backgroundColor: sdpclosetCategoryColor,

          };
          const sdpclosetCategoryName = sdpData?.find(
            (category) => category?.sdp_category === sdpnearestCategories
          )?.name;

          return (
            <React.Fragment key={item?.id}>
              <nav aria-label="breadcrumb" key={item?.id}>
                <div className="back-btn">
                  <Link onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left"></i> Back
                  </Link>
                </div>
              </nav>
              <div className="row justify-content-end" key={item?.id}>
                <div className="col-lg-3 col-md-6 heart_icon">
                  {/* <i
                    className={`fa-regular fa-heart${isInWishlist(item?.product_id, item?.variants?.[0]?.id) ? " active" : ""}`}
                    onClick={() => {
                      dispatch(
                        addToWishlist({
                          pId: item?.product_id,
                          vId: item?.variants?.[0]?.id,
                        })
                      );
                    }}
                  ></i> */}
                  <div className="product_detail">
                    {chosenDiscountName && (
                      <div className="off">{chosenDiscountName}</div>
                    )}
                    <img
                      src={item?.product_image.lg}
                      alt={item?.variants[0].name}
                      className="product-image"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="product_add mb-3">
                    <h1>{currentProSelectedVariant?.name}</h1>
                    {item.flower_type && item.flower_type.length !== 0 && (
                      <div className="d-flex">
                        <p className="indica">{item?.flower_type}</p>
                        <span>Dr. Jolly's</span>
                      </div>
                    )}

                    <div className="d-flex mb-4 mt-1">
                      <div className="total-section">
                        <div className="pMeta_wrap">
                          {item?.test_results?.total_thc?.current !== 0.0 && (
                            <span className="cbd">
                              {Constant.TOTAL_THC} :{" "}
                              {item?.test_results?.total_thc?.current} {item?.test_results?.total_thc?.unit_type}
                            </span>
                          )}
                        </div>
                        <div className="img-section">
                          <img src={sdpCategoryIcon ? sdpCategoryIcon : "/images/strain-data.png"} alt="sdp_icon" />
                        </div>
                      </div>
                    </div>
                    <span className="product-price">
                      {currentProSelectedVariant?.discounted_med_price_cents ||
                        currentProSelectedVariant?.discounted_price_cents ? (
                        <span>
                          <strike>
                            ${(
                              currentProSelectedVariant?.med_price_cents / 100 ||
                              currentProSelectedVariant?.price_cents / 100
                            ).toFixed(2)}
                          </strike>
                          <span className="price">
                            &nbsp;&nbsp;$
                            {(
                              currentProSelectedVariant?.discounted_med_price_cents / 100 ||
                              currentProSelectedVariant?.discounted_price_cents / 100
                            ).toFixed(2)}
                          </span>
                        </span>
                      ) : (
                        <span className="price">
                          $
                          {(
                            currentProSelectedVariant?.price_cents / 100 ||
                            currentProSelectedVariant?.med_price_cents / 100
                          ).toFixed(2)}
                        </span>
                      )}
                    </span>

                    <div className="weight-section">
                      <div className="weight position-relative">
                        <p>{Constant.WEIGHT}</p>

                        {item?.variants?.length > 1 ? (
                          <>
                            <select
                              className="form-control form-select"
                              value={currentProSelectedVariant?.id}
                              onChange={(e) => handleVariantChange(e, item?.id)}
                            >
                              {item?.variants?.map((vt) => (
                                <option key={vt?.id} value={vt?.id}>
                                  {`${vt?.unit} ${vt?.unit_type}`}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : (
                          <span>{`${item?.variants[0]?.unit} ${item?.variants[0]?.unit_type}`}</span>
                        )}
                      </div>
                      <div className="plus_minus_box">
                        <p className="Quantity">{Constant.QUANTITY}</p>
                        <div className="plus_minus">
                          <span
                            className={`minus ${currentProSelectedVariant?.quantity <= 1
                              ? "disabled"
                              : ""
                              }`}
                            onClick={() => decrementQuantityHandler()}
                          >
                            -
                          </span>

                          <span className="value">
                            {currentProSelectedVariant?.quantity}
                          </span>

                          <span
                            className={`add ${currentProSelectedVariant?.quantity_on_hand <= 0
                              ? "disabled"
                              : ""
                              }`}
                            onClick={() => incrementQuantityHandler()}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <a className="huppr_up">
                    {item?.variants[0]?.quantity_on_hand <= 0 ? (
                      <p className="out-of-stock">{Constant.OUT_OF_STOCK}</p>
                    ) : (
                      <>
                        <p>
                          {Constant.HURRY_UP}{" "}
                          {Math.floor(currentProSelectedVariant?.quantity_on_hand)}{" "}
                          {Constant.LEFT}
                        </p>
                        <button
                          className="btn btn-info"
                          onClick={() => addToCartItem(currentProSelectedVariant)}
                          disabled={disabledButtons[item.id]}
                        >
                          {Constant.ADD_TO_CART}{" "}
                          <i className="fa fa-cart-shopping prd_dtl"></i>
                        </button>
                      </>
                    )}
                  </a>
                </div>
              </div>

              {/* Addition detail section */}
              <div className="additional-section mt-4">
                <h5> Additional Cannabinoids</h5>
                <div className="price-discout-section d-flex mt-3">
                  {item?.test_results?.thc?.current !== 0.0 && (
                    <span className="cbd">
                      {Constant.THC} {item?.test_results?.thc?.current}
                      {item?.test_results?.thc?.unit_type}{" "}
                    </span>
                  )}
                  {item?.test_results?.cbd?.current !== 0.0 && (
                    <span className="cbd">
                      {Constant.CBD} {item?.test_results?.cbd?.current}
                      {item?.test_results?.cbd?.unit_type}
                    </span>
                  )}
                </div>
                <div className="extra-info-section mt-4 mb-4">
                  <div className="row">
                    {item?.effect_ids && item.effect_ids.length > 0 && (
                      <div className="col-md-4 col-sm-4">
                        <h5>Effects</h5>
                        <div className="effect-section">
                          {/* Map through effect IDs and display their names */}
                          {item?.effect_ids.map((effectId) => (
                            <div className="effect-name" key={effectId}>
                              {/* <img src={window.location.origin + "/images/3.png"} alt="effect"></img>*/}
                               <img src={`/images/${getNamesFromIds([effectId], menuFeedData.product_data.effects)}.png`} alt="effect"></img>
                              <p>{getNamesFromIds([effectId], menuFeedData.product_data.effects)}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {item?.flavor_ids && item.flavor_ids.length > 0 && (
                      <div className="col-md-4 col-sm-4">
                        <h5>Flavour</h5>
                        <div className="effect-section">
                          {/* Map through flavor IDs and display their names */}
                          {item?.flavor_ids.map((flavorId) => (
                            <div className="effect-name" key={flavorId}>
                              {/* <img src={window.location.origin + "/images/5.png"} alt="effect"></img> */}
                              <img src={`/images/${getNamesFromIds([flavorId], menuFeedData.product_data.flavors)}.png`} alt="flavors"></img>
                              <p>{getNamesFromIds([flavorId], menuFeedData.product_data.flavors)}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {item?.terpene_ids && item.terpene_ids.length > 0 && (
                      <div className="col-md-4 col-sm-4">
                        <h5>Trepenes</h5>
                        <div className="effect-section">
                          {/* Map through terpene IDs and display their names */}
                          {item?.terpene_ids.slice(0, 3).map((terpeneId) => (
                            <div className="effect-name" key={terpeneId}>
                              {/* style={{ backgroundColor: getColorFromIds([terpeneId], menuFeedData.product_data.terpenes) }} */}
                              
                              <img src={window.location.origin + "/images/Terpene Icon.png"} alt="effect"></img>
                              <p>{getNamesFromIds([terpeneId], menuFeedData.product_data.terpenes)}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <span className="terpens_text">Terpens </span>
                {sdp_logo && (
                  <img className="sdp_logo_img" src={sdp_logo} alt="sdpImage" />
                )}
                <div className="more-product-detail">
                  <div className="product-graph mt-4 ">
                    <div className="product-catogory mb-4">

                      {sdpCategoryName && (
                        <><span>Catagory for this Product:{" "}</span><span>
                          <span className="sdpbadge" style={style1}>
                            {sdpCategoryName}
                          </span>
                        </span></>
                      )}
                    </div>
                    <img
                    className="mx-auto d-block"
                    src={item?.sdp_compass ? item?.sdp_compass : "/images/product-graph.png"}
                    alt="product-graph"
                    ></img>
                  </div>

                  <div className="details mt-4">
                    <h6 className="terpens_heading">
                      What's your favourite color?
                    </h6>
                    <p>
                      The Strain Data compass is part of a larger research
                      effort called the Strain Data Project (SDP), from which
                      this information was derived.
                    </p>
                    <p>
                      SDP utilized data from thousands of tested cannabis
                      samples. The data was analyzed and aggregated in order to
                      discover similarities and differences between cannabis
                      products.
                    </p>
                    <p>
                      Each colored coded category is defined primarily by the
                      most dominant terpene or combination of terpenes. This
                      creates a signature shape on a radar chart.
                    </p>
                    {item?.coa_url && (
                      <a href={item?.coa_url} target="_blank">
                        Learn more about the Strain Data Project
                      </a>
                    )}

                  </div>

                  <div className="most-detail-section">

                    {sdpCategoryDescription && (
                      <p className="mt-4">
                        <span className="detail-heading">Category Description:{" "}</span>
                        {sdpCategoryDescription}
                      </p>
                    )}

                    <div className="d-flex closet_catagories">
                      {sdpclosetCategoryName && (
                        <><span>Closest Catagories: {" "}</span><span>
                          <span className="sdpbadge" style={style}>
                            {sdpclosetCategoryName}
                          </span>
                        </span></>
                      )}
                    </div>
                    <div className="d-flex mt-4 d-none">
                      <span> Further Catagory: </span>{" "}
                      <span>
                        <Badge pill bg="warning" text="dark">
                          Blue
                        </Badge>
                      </span>
                    </div>
                    <h5 className="mt-4"> Top 3 Most Abundant Terpens</h5>
                    <div className="abundant-section">
                      <div className="terpen-list">
                        <p>Mycerene</p>
                        <p>Limonene</p>
                        <p>a-pinene</p>
                        <p>Total terpenes</p>
                      </div>
                      <div className="terpen-percent">
                        <p>0.94%</p>
                        <p>0.34%</p>
                        <p>0.25%</p>
                        <p>0.3%</p>
                      </div>
                    
                    <div className="row ">
                      <div className="powered by">Powered by</div>
                      <div className="confidence-logo">
                        {iron_stem_logo && (
                          <img src={iron_stem_logo} alt="certified-logo"/>
                        )}
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
        {/* Related Categories product Section */}
        {relatedSDPCategories?.length > 0 && (
          <>
            <div className="row g-4 mt-2 text-center">
              <div className="col-md-12">
                <h5>More product for the orange category</h5>
              </div>
            </div>
            <section id="product_section">
              <Slider {...sliderSettings}>
                {relatedSDPCategories.map((item) => {
                  const discountIdForMedPrice =
                    item?.variants[0]?.discount_id_for_med_price;
                  const discountIdForPrice =
                    item?.variants[0]?.discount_id_for_price;

                  const discountForMedPrice =
                    menuFeedData?.product_data?.discounts.find(
                      (d) => d.id === discountIdForMedPrice
                    );
                  const discountForPrice =
                    menuFeedData?.product_data?.discounts.find(
                      (d) => d.id === discountIdForPrice
                    );

                  const discountNameForMedPrice = discountForMedPrice?.item_display_name;
                  const discountNameForPrice = discountForPrice?.item_display_name;

                  const chosenDiscountName =
                    discountNameForMedPrice || discountNameForPrice;

                  return (
                    <React.Fragment key={item?.id}>
                      <div className="card product_cart" key={item?.id}>
                        {chosenDiscountName && (
                          <div className="off">{chosenDiscountName}</div>
                        )}
                        
                        <img
                          src={item?.product_image.lg}
                          alt="Product_Image"
                          onClick={() =>
                            navigate(
                              item?.brand_id
                                ? `/${slugify(item?.brand)}/${slugify(
                                  item?.variants[0]?.name
                                )}`
                                : `/${slugify(item?.variants[0]?.name)}`,
                              {
                                state: {
                                  brands: item?.brand_id,
                                  product_id: item?.product_id,
                                },
                              }
                            )
                          }
                        />

                        <h1
                          className="name"
                          onClick={() =>
                            navigate(
                              item?.brand_id
                                ? `/${slugify(item?.brand)}/${slugify(
                                  item?.variants[0]?.name
                                )}`
                                : `/${slugify(item?.variants[0]?.name)}`,
                              {
                                state: {
                                  brands: item?.brand_id,
                                  product_id: item?.product_id,
                                },
                              }
                            )
                          }
                        >
                          {item?.variants[0]?.name}{" "}
                        </h1>
                        <div className="flower_types">
                          {item.flower_type && item.flower_type.length !== 0 && (
                            <React.Fragment>
                              <p className="indica">{item?.flower_type}</p>{" "}
                            </React.Fragment>
                          )}
                          <span>Dr. Jolly</span>
                         
                        </div>
                        <div className="mt-2 mb-4 d-flex">
                          {item?.test_results?.thc?.current !== 0.0 && (
                            <span className="thc">
                              {Constant.THC} {item?.test_results?.thc?.current}
                              {item?.test_results?.thc?.unit_type}{" "}
                            </span>
                          )}
                          {item?.test_results?.cbd?.current !== 0.0 && (
                            <span className="cbd">
                              {Constant.CBD} {item?.test_results?.cbd?.current}
                              {item?.test_results?.cbd?.unit_type}
                            </span>
                          )}
                          {/* <span> <img src={window.location.origin + "/images/strain-data.png"} alt='strain data img' className="strainimg"/></span> */}
                        </div>
                        <span className="regular-price">{displayPrice1(item)}</span>
                        <div>
                          {item?.variants[0]?.quantity_on_hand <= 0 ? (
                            <p className="out-of-stock">{Constant.OUT_OF_STOCK}</p>
                          ) : (
                            <>
                              <button
                                className="btn btn-info addtocart_btn"
                                onClick={() => {
                                  setSelectedProducts(item);
                                  setSelectedProductVariant(item?.variants?.[0]);
                                }}
                              >
                                {Constant.ADD_TO_CART}{" "}
                                <i className="fa fa-cart-shopping prd_dtl"></i>
                              </button>
                            </>
                          )}
                        </div>
                        <div className="back_product_cart">
                          {chosenDiscountName && (
                            <div className="off">{chosenDiscountName}</div>
                          )}

                          <div class="rectangle closeActive">
                            <i className="fa fa-angle-left"></i>
                          </div>

                          <img src={item?.product_image.lg} alt="Product_Image" />

                          <div className="quantity_dropdown">
                            {item?.variants?.length > 1 ? (
                              <>
                                <select
                                  className="form-control form-select"
                                  value={selectedProductVariant?.id}
                                  onChange={(e) => handleVariantChanges(e)}
                                >
                                  {item?.variants?.map((vt) => (
                                    <option key={vt?.id} value={vt?.id}>
                                      {`${vt?.unit} ${vt?.unit_type}`}
                                    </option>
                                  ))}
                                </select>
                              </>
                            ) : (
                              <span className="form-control">{`${item?.variants[0]?.unit} ${item?.variants[0]?.unit_type}`}</span>
                            )}
                          </div>
                          <div className="back_quantity_button">
                            <div class="plus_minus_box">
                              <div class="plus_minus">
                                <span
                                  className={`minus ${selectedProductVariant?.quantity <= 1
                                    ? "disabled"
                                    : ""
                                    }`}
                                  onClick={() => decrementQuantityHandlers()}
                                >
                                  -
                                </span>

                                <span className="value">
                                  {selectedProductVariant?.quantity}
                                </span>

                                <span
                                  className={`add ${selectedProductVariant?.quantity_on_hand <= 0
                                    ? "disabled"
                                    : ""
                                    }`}
                                  onClick={() => incrementQuantityHandlers()}
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="back_product_name">
                            <h1 className="name">{selectedProductVariant?.name} </h1>
                          </div>

                          <div
                            className="confirmbtn"
                            onClick={() => addToCartitems(selectedProductVariant)}
                          >
                            <button class="Checkout">confirm &nbsp;&nbsp;${renderPrice()} </button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Slider>
            </section>
          </>
        )}
        {/* Related Brand Products Section */}
        {relatedProducts?.length > 0 && (
          <>
            <div className="row g-4 mt-2 text-center">
              <div className="col-md-12">
                <h5>More product from {relatedProducts[0].brand} Brand</h5>
              </div>
            </div>
            <section id="product_section">
              <Slider {...sliderSettings}>
                {relatedProducts.map((item) => {
                  const discountIdForMedPrice =
                    item?.variants[0]?.discount_id_for_med_price;
                  const discountIdForPrice =
                    item?.variants[0]?.discount_id_for_price;

                  const discountForMedPrice =
                    menuFeedData?.product_data?.discounts.find(
                      (d) => d.id === discountIdForMedPrice
                    );
                  const discountForPrice =
                    menuFeedData?.product_data?.discounts.find(
                      (d) => d.id === discountIdForPrice
                    );

                  const discountNameForMedPrice = discountForMedPrice?.item_display_name;
                  const discountNameForPrice = discountForPrice?.item_display_name;

                  const chosenDiscountName =
                    discountNameForMedPrice || discountNameForPrice;

                  return (
                    <React.Fragment key={item?.id}>
                      <div className="card product_cart" key={item?.id}>
                        {chosenDiscountName && (
                          <div className="off">{chosenDiscountName}</div>
                        )}
                        
                        <img
                          src={item?.product_image.lg}
                          alt="Product_Image"
                          onClick={() =>
                            navigate(
                              item?.brand_id
                                ? `/${slugify(item?.brand)}/${slugify(
                                  item?.variants[0]?.name
                                )}`
                                : `/${slugify(item?.variants[0]?.name)}`,
                              {
                                state: {
                                  brands: item?.brand_id,
                                  product_id: item?.product_id,
                                },
                              }
                            )
                          }
                        />
                        <h1
                          className="name"
                          onClick={() =>
                            navigate(
                              item?.brand_id
                                ? `/${slugify(item?.brand)}/${slugify(
                                  item?.variants[0]?.name
                                )}`
                                : `/${slugify(item?.variants[0]?.name)}`,
                              {
                                state: {
                                  brands: item?.brand_id,
                                  product_id: item?.product_id,
                                },
                              }
                            )
                          }
                        >
                          {item?.variants[0]?.name}{" "}
                        </h1>
                        <div className="flower_types">
                          {item.flower_type && item.flower_type.length !== 0 && (
                            <React.Fragment>
                              <p className="indica">{item?.flower_type}</p>{" "}
                            </React.Fragment>
                          )}
                          <span>Dr. Jolly</span>
                        </div>
                        <div className="mt-2 mb-4 d-flex">
                          {item?.test_results?.thc?.current !== 0.0 && (
                            <span className="thc">
                              {Constant.THC} {item?.test_results?.thc?.current}
                              {item?.test_results?.thc?.unit_type}{" "}
                            </span>
                          )}
                          {item?.test_results?.cbd?.current !== 0.0 && (
                            <span className="cbd">
                              {Constant.CBD} {item?.test_results?.cbd?.current}
                              {item?.test_results?.cbd?.unit_type}
                            </span>
                          )}
                        </div>
                        <span className="regular-price">{displayPrice1(item)}</span>
                        <div>
                          {item?.variants[0]?.quantity_on_hand <= 0 ? (
                            <p className="out-of-stock">{Constant.OUT_OF_STOCK}</p>
                          ) : (
                            <>
                              <button
                                className="btn btn-info addtocart_btn"
                                onClick={() => {
                                  setSelectedProducts(item);
                                  setSelectedProductVariant(item?.variants?.[0]);
                                }}
                              >
                                {Constant.ADD_TO_CART}{" "}
                                <i className="fa fa-cart-shopping prd_dtl"></i>
                              </button>
                            </>
                          )}
                        </div>
                        <div className="back_product_cart">
                          {chosenDiscountName && (
                            <div className="off">{chosenDiscountName}</div>
                          )}

                          <div class="rectangle closeActive">
                            <i className="fa fa-angle-left"></i>
                          </div>

                          <img src={item?.product_image.lg} alt="Product_Image" />

                          <div className="quantity_dropdown">
                            {item?.variants?.length > 1 ? (
                              <>
                                <select
                                  className="form-control form-select"
                                  value={selectedProductVariant?.id}
                                  onChange={(e) => handleVariantChanges(e)}
                                >
                                  {item?.variants?.map((vt) => (
                                    <option key={vt?.id} value={vt?.id}>
                                      {`${vt?.unit} ${vt?.unit_type}`}
                                    </option>
                                  ))}
                                </select>
                              </>
                            ) : (
                              <span className="form-control">{`${item?.variants[0]?.unit} ${item?.variants[0]?.unit_type}`}</span>
                            )}
                          </div>
                          <div className="back_quantity_button">
                            <div class="plus_minus_box">
                              <div class="plus_minus">
                                <span
                                  className={`minus ${selectedProductVariant?.quantity <= 1
                                    ? "disabled"
                                    : ""
                                    }`}
                                  onClick={() => decrementQuantityHandlers()}
                                >
                                  -
                                </span>

                                <span className="value">
                                  {selectedProductVariant?.quantity}
                                </span>

                                <span
                                  className={`add ${selectedProductVariant?.quantity_on_hand <= 0
                                    ? "disabled"
                                    : ""
                                    }`}
                                  onClick={() => incrementQuantityHandlers()}
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="back_product_name">
                            <h1 className="name">{selectedProductVariant?.name} </h1>
                          </div>

                          <div
                            className="confirmbtn"
                            onClick={() => addToCartitems(selectedProductVariant)}
                          >
                            <button class="Checkout">confirm &nbsp;&nbsp;${renderPrice()} </button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Slider>
            </section>
          </>
        )}
      </div>
    </section>
  );
};

export default ProductDetail;