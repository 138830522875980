import React, { useState } from "react";
import { putQuotesUpdateItemsApi } from "../store/InitializeQuotesPostAPI";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Constant from "../Constants";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Review = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isIconDisabled, setIsIconDisabled] = useState(false);
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("/");
  };

  const items = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.items
  );
  const reference_no = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.reference_no
  );
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlugId = slugId;
  const discountNames = useSelector(state => {

    const discounts = state?.discountData?.info?.coupons;

    return items?.map(item => {
      // Assuming discount_ids represent discount names
      const itemDiscounts = item.discount_ids.map(discountId => {
        const discount = discounts.find(d => d.id === discountId);
        return discount ? discount.display_name : null;
      });

      return {
        itemId: item.id,
        discounts: itemDiscounts,
      };
    });
  });

  const changeFinalQuantity = (item, inc) => {
    if (isIconDisabled) {
      return; // If the icon is disabled, do not update the quantity
    }
  
    const slugId = localStorage.getItem("selectedVenue") || defaultSlugId;
  
    dispatch(
      putQuotesUpdateItemsApi({
        slug: slugId,
        reference_no,
        quote: {
          items: items.map((it) => {
            if (
              it.sku_id === item?.sku_id &&
              it.unit === item?.unit &&
              it.unit_type === item?.unit_type
            ) {
              const newQuantity = inc ? it.quantity + 1 : it.quantity - 1;
              const updatedQuantity = Math.min(
                Math.max(newQuantity, 1), // Ensure quantity is at least 1
                it.quantity_on_hand // Ensure quantity doesn't exceed quantity_on_hand
              );
  
              if (updatedQuantity !== it.quantity) {
                dispatch({
                  type: 'PUT_QUOTES_UPDATE_ITEMS',
                  payload: {
                    sku_id: it.sku_id,
                    unit: it.unit,
                    unit_type: it.unit_type,
                    quantity: updatedQuantity,
                  },
                });
                toast.success("Product quantity updated in cart");
              } else if (updatedQuantity === 1 && !inc) {
                toast.warning("Minimum 1 quantity required");
              } else if (updatedQuantity === it.quantity) {
                toast.warning("You have reached the maximum quantity");
              }
  
              return { ...it, quantity: updatedQuantity };
            }
  
            return it;
          }),
        },
      })
    );
    // Disable the icon for 15 milliseconds
    if (inc) {
      setIsIconDisabled(true);
      setTimeout(() => {
        setIsIconDisabled(false);
      }, 2000);
    }
  };
  
  const handleRemove = (id) => {
    if (reference_no) {
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
          quote: {
            items: [
              ...items
                .filter((it) => {
                  return it.id !== id;
                })
                .map((its) => {
                  return {
                    sku_id: its?.sku_id,
                    unit: its?.unit,
                    unit_type: its?.unit_type,
                    quantity: its?.quantity,
                  };
                }),
            ],
          },
        })
      );
    }
  };
  return (
    <>
      {items?.length === 0 ? (
        <div className="empty_cart text-center container">
          <img src="./images/Group 778.png" />
          <button className="btn btn-info mt-4 w-25" onClick={navigateHome}>
            {Constant.RETURN_TO_SHOPPING}
          </button>
        </div>
      ) : (
        <div className="container">
          <div className="pick_up">
            {items?.map((item) => (
              <>
                <div className="productminicart card_2">
                  <div className="img_box">
                    <img src={item?.product_image?.lg} alt="Product_Image" />
                  </div>
                  <div className="det_box">
                    <div className="d-flex pt-2">
                      <h1 className="name">{item?.name}</h1>

                      <div className="static">
                        $ {((item.price / 100) * item?.quantity - (item?.discount / 100)).toFixed(2)}
                        <p>
                          {discountNames &&
                            discountNames.length > 0 &&
                            discountNames.find(d => d.itemId === item.id) &&
                            discountNames.find(d => d.itemId === item.id)?.discounts ? (
                            <>
                              <i className="fa fa-tag"></i>{" "}
                              {discountNames.find(d => d.itemId === item.id).discounts.map((discount, index) => (
                                <span key={index}>
                                  {discount}
                                  {index < discountNames.find(d => d.itemId === item.id).discounts.length - 1 && <br />}
                                </span>
                              ))}
                            </>
                          ) : (
                            ''
                          )}
                        </p>

                      </div>
                      <div>
                        <span></span>
                      </div>
                    </div>

                    <div className="price"><strike>$ {(item.price / 100 * item?.quantity).toFixed(2)}</strike></div>
                    <div className="itemquantity position-relative">
                      <div className="plus_minus_box box_label">
                        <label>Quantity</label>
                        <div className="plus_minus">
                          <span
                            className={`minus ${item?.quantity <= 1
                              ? "disabled"
                              : ""
                              }`}
                            onClick={() => changeFinalQuantity(item, false)}
                          >
                            -
                          </span>
                          <span className="value">{item?.quantity}</span>
                          <span
                            className={`add ${isIconDisabled ? "disabled" : ""}`}
                            onClick={() => changeFinalQuantity(item, true)}
                          >
                            +
                          </span>
                        </div>
                      </div>
                      <div className="delete-icon" onClick={() => handleRemove(item.id)}>
                        <i
                          className="fa fa-trash"

                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">

                </div>

              </>
            ))}
            <button
              className="control-btn paymentbtn"
              type="submit"
              value="submit"
              onClick={() => {
                props.handleClick(1);
                props.setActiveAccordionKey("2");
              }}
            >
              {Constant.CONTINUE}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Review;