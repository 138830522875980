import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Constant from "../Constants";
import { useNavigate } from "react-router-dom";
import { submitOrderData } from "../store/SubmitOrderAction";
import { emptyInitializeQuotes } from "../store/slice/InitializeQuotesAPISlice";
import { emptySubmitOrder } from "../store/slice/SubmitOrderSlice";
import { emptyUserinfoSlice } from "../store/slice/UserInfoSlice";
import { primaryColor } from '../store/slice/ContentSlice.js';

const ProcessToCheckOut = ({ formStep }) => {
  const primaryColorValue = useSelector(primaryColor);
  useEffect(() => {
    document.documentElement.style.setProperty('--dynamic-color', primaryColorValue);
  }, [primaryColorValue]);
  const calculateTotalPrice = (totals) => {
    if (totals && totals.total) {
      return totals.total / 100;
    } else {
      return null;
    }
  };

  // Inside your component
  const totals = useSelector((store) => store?.initialQuotes?.info?.quote?.totals);
  const totalPrice = calculateTotalPrice(totals);

  const navigate = useNavigate();

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("/");
  };

  const dispatch = useDispatch();

  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;

  const reference_no = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.reference_no
  );

  const submitOrder = () => {
    const slugId = localStorage.getItem("selectedVenue") || defaultSlug;
    if (reference_no) {
      dispatch(
        submitOrderData({
          slug: slugId,
          reference_no,
          data: {},
        })
      );
    }
    dispatch(emptyInitializeQuotes())
    dispatch(emptyUserinfoSlice())
    dispatch(emptySubmitOrder())
    // 👇️ navigate to /
    navigate("/thank-you");
  };
  return (
    <div className="container" id="">
      <div className="row card p-4 mt-4">
        <div className="col-md-12">
          <div className="pick_up">
            <span className="pick_up_store1 fw-bold">
              {Constant.CART_TOTALS}
            </span>
            <hr />
            <div className="total_pay">
              <div className="amount">
                <p>{Constant.SUBTOTAL}</p>
                <p>${(totals?.subtotal / 100).toFixed(2)}</p>
              </div>
              <div className="amount">
                <p className="discount">{Constant.DISCOUNT}</p>
                <p className="discount">
                  {Constant.MINUS_DOLLAR}
                  {(totals?.discount / 100).toFixed(2)}
                </p>
              </div>
              <div className="amount">
                <p>{Constant.TAX}</p>
                <p>
                  {Constant.DOLLAR}
                  {(totals?.tax / 100).toFixed(2)}
                </p>
              </div>
              <div className="amount">
                <p className="fw-bold">{Constant.TOTAL}</p>
                <p className="fw-bold">
                  {Constant.DOLLAR}
                  {totalPrice?.toFixed(2)}
                </p>
              </div>
              <div className="checkout">
                <button
                  type="button"
                  className="btn btn-info Checkoutbtn"
                  disabled={!formStep.step3}
                  onClick={submitOrder}
                >
                  {Constant.PROCEED_TO_CHECKOUT}
                </button>
                <button type="button" class="btn btn-info Checkoutbtn border" onClick={navigateHome}>
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProcessToCheckOut;