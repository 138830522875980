import React from "react"
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchMenuFeedApi = createAsyncThunk(
  "menuFeed/fetchInfo",
  async (payload, thunkAPI) => {
    const selectedVenue = payload || localStorage.getItem("selectedVenue");

    try {
      const response = await fetch(`https://qa-ecomm-app.posabit.com/mcx/oregon_tops/venue/${selectedVenue}/v1/menu_feeds/${window.posabitmenuwidget.config.feed_id}/product_data`, {

        headers: {
          merchantToken: window.posabitmenuwidget.config.api_key,
          authorization: "Bearer 1vm1ax-tIxpocYteXD1FWA",
        },
      });

      if (!response.ok) {
        <h2>No products found</h2>
      }

      const data = await response.json();

      const dataWithQuantity = {
        ...data,
        product_data: {
          ...data.product_data,
          menu_items: data?.product_data?.menu_items.map((item) => {
            return {
              ...item, variants: item.variants.map((va, i) => {
                if (i === 0) {
                  return { ...va, selected: true, quantity: 1, }
                } else {
                  return { ...va, selected: false, quantity: 1, }
                }
              }),
              brand:data?.product_data?.brands.filter(br=>{
               if(br?.id === item?.brand_id){
                return br
               }
              })[0]?.name
            };
          }),
        },
      };
      return dataWithQuantity;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
