import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate,Link } from 'react-router-dom';
import PickUpDelivery from "./PickUpDelivery";
import UserInformation from "./UserInformation";
import Payment from "./Payment";
import Review from "./Review";
import ProcessToCheckOut from "./ProcessToCheckOut";
import Accordion from "react-bootstrap/Accordion";
import * as Constant from "../Constants";

const CheckOut = () => {
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });

  const [activeAccordionKey, setActiveAccordionKey] = useState("0");
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  const reviewItem = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.items
  );
  const items = [
    {
      title: Constant.REVIEW,
      icon: <i className="fa fa-info"></i>,
      content: (
        <Review
          handleClick={handleClick}
          setActiveAccordionKey={setActiveAccordionKey}
        />
      ),
    },
  ];

  // Conditionally add UserInformation and Payment components based on reviewItem length
  if (reviewItem?.length >= 1) {
    items.push(
      {
        title: Constant.ENTER_CUSTOMER,
        icon: <i className="fa fa-credit-card"></i>,
        content: <UserInformation handleClick={handleClick} />,
      },
      {
        title: Constant.PAYMENT_METHOD,
        icon: <i className="fa fa-edit"></i>,
        content: <Payment handleClick={handleClick} step={formStep} setFormStep={setFormStep} />,
      }
    );
  }

  const showAdditionalComponents = reviewItem?.length >= 1;

  return (
    <div className="App payemnt_checkout_page">
      <div className="container">
        <nav aria-label="breadcrumb">
          <div className="back-btn">
            <Link onClick={() => navigate(-1)}>
              <i className="fa fa-arrow-left"></i> Back
            </Link>
          </div>
        </nav>
        <div className="custom_accordion">
          <div className="accordion">
            {items.map((item, index) => (
              <div className="accordion-item" key={item.title}>
                <div className="accordion-header">
                  <button
                    className="accordion-button"
                    onClick={() => handleClick(index)}
                  >
                    <span className="free_icon">{item.icon}</span>
                    <span className="pick_up_store">{item.title}</span>
                  </button>
                </div>
                {index === activeIndex && (
                  <div className="accordion-body">{item.content}</div>
                )}
              </div>
            ))}
            {showAdditionalComponents && (
              <ProcessToCheckOut formStep={formStep} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;