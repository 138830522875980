import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userInfoData } from "../store/UserInfoAction";
import * as Constant from "../Constants";

const Payment = (props) => {
  const [paymentMethod, setPaymentMethod] = useState("link");

  const reference_no = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.reference_no
  );

  const dispatch = useDispatch();
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;
  const selectItem = (e) => {
    if (reference_no) {
      const slugId = localStorage.getItem("selectedVenue") || defaultSlug;
      dispatch(
        userInfoData({
          slug: slugId,
          reference_no,
          // data: {
            quote: {
              payment: {
                requested_payment_method: paymentMethod,
              },
            },
          // },
        })
      );
      e.preventDefault();
    }
    //Update the form step to indicate completion of step 1
        props.setFormStep((prevState) => ({
          ...prevState,
          step3: true,
        }));
    props.handleClick(3);
  };

  return (
    <div className="container">
      <form onSubmit={selectItem}>
        <div className="col-md-12">
          <div className="info_box">
            <p className="mb-0 fw-bold">{Constant.GOVERNMENT_ID}</p>
            <p>{Constant.THIS_WILL}</p>

            <div className="row">
              <div className="col-md-12">
                <div className="form-check mb-3">
                  <label className="form-check-label">
                    <input
                      // defaultChecked
                      required
                      className="form-check-input"
                      type="radio"
                      name="remember"
                      value="cash"
                      checked={paymentMethod === "cash"}
                      onChange={() => setPaymentMethod("cash")}
                    />{" "}
                    {Constant.CASH}
                  </label>
                </div>

                <div className="form-check mb-3">
                  <label className="form-check-label">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      name="remember"
                      value="credit"
                      checked={paymentMethod === "credit"}
                      onChange={() => setPaymentMethod("credit")}
                    />{" "}
                    {Constant.CREDIT}
                  </label>
                </div>

                <div className="form-check mb-3">
                  <label className="form-check-label">
                    <input
                      required
                      className="form-check-input"
                      type="radio"
                      name="remember"
                      value="link"
                      checked={paymentMethod === "link"}
                      onChange={() => setPaymentMethod("link")}
                    />{" "}
                    <p>
                      <img src="/images/posabitpay.png" alt="pay" />
                      Pay <span>No fee</span>
                    </p>
                    <p>
                      Secure electronic payment directly from checking account
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button
            className={
              paymentMethod === "link"
                ? "control-btn paymentbtn payment-disable"
                : "control-btn paymentbtn"
            }
            // id="next-btn"
            type="submit"
            value="submit"
          >
            {Constant.CONTINUE}
          </button>
          <button
            className={
              paymentMethod === "cash" || paymentMethod === "credit"
                ? "control-btn1 paymentbtn link-disable"
                : "control-btn1 paymentbtn"
            }
            // id="next-btn"
            type="submit"
            value="submit"
          >
            {Constant.LINK_ACCOUNT}
          </button>
        </div>
      </form>
      {/* </div> */}
    </div>
  );
};
export default Payment;


