import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Constant from "../Constants";
import slugify from "react-slugify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { selectMenuFeed } from "../store/slice/MenuFeedAPISlice";
import { selectWishlist } from "../store/slice/wishlistSlice";

import {
  addToWishlist,
  removeFromWishlist,
} from "../store/slice/wishlistSlice";
import {
  incrementQuantity,
  decrementQuantity,
  changeVariant,
} from "../store/slice/MenuFeedAPISlice";
import {
  postInitializeQuotesApi,
  putQuotesUpdateItemsApi,
} from "../store/InitializeQuotesPostAPI";
import { sliderSettings } from "../utility/sliderUtils.js";

const ProductSlider = (props) => {
  const [disabledButtons, setDisabledButtons] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedProductVariant, setSelectedProductVariant] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuFeedData = useSelector(selectMenuFeed);
  const [activeProductCard, setActiveProductCard] = useState(null);
  const [currentProSelectedVariant, setCurrentProSelectedVariant] = useState(
    {}
  );
  const isMedicalOnly =
    localStorage.getItem("menuType") === "Recreational" ? false : true;
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;
  const reference_no = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.reference_no
  );

  const items = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.items
  );

  // Variant chnage
  useEffect(() => {
    if (Object.keys(selectedProduct).length !== 0) {
      setCurrentProSelectedVariant(
        menuFeedData?.product_data?.menu_items
          ?.filter((item) => {
            if (item.product_id == selectedProduct.product_id) {
              return item;
            }
          })?.[0]
          .variants?.filter((va) => va?.selected)[0]
      );
    }
  }, [menuFeedData]);

  const handleVariantChange = (e) => {
    setSelectedProductVariant(
      selectedProduct.variants.filter((vr) => {
        if (vr.id === e.target.value) {
          return vr;
        }
      })?.[0]
    );
    // dispatch(changeVariant({ productId: pId, variantId: e.target.value }));
  };

  /* Add to cart */

  const addToCartitem = async (selectedProduct) => {
    // Disable the button
    setDisabledButtons((prevDisabledButtons) => ({
      ...prevDisabledButtons,
      [selectedProduct.id]: true,
    }));
    try {
      const slugId = localStorage.getItem("selectedVenue") || defaultSlug;
      const existingCartItem = items?.find(
        (it) =>
          it.sku_id === selectedProduct?.sku_id &&
          it.unit === selectedProductVariant?.unit &&
          it.unit_type === selectedProductVariant?.unit_type
      );

      if (
        existingCartItem &&
        existingCartItem.quantity === selectedProductVariant?.quantity_on_hand
      ) {
        toast.warning("You have reached the maximum quantity");
      } else {
        if (reference_no) {
          const found = items.filter((it) => {
            if (
              it.sku_id === selectedProduct?.sku_id &&
              it.unit === selectedProductVariant?.unit &&
              it.unit_type === selectedProductVariant?.unit_type
            ) {
              return it;
            }
          });
          if (found.length > 0) {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    ...items.map((it) => {
                      if (
                        it.sku_id === selectedProduct?.sku_id &&
                        it.unit === selectedProductVariant?.unit &&
                        it.unit_type === selectedProductVariant?.unit_type
                      ) {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity:
                            it?.quantity + selectedProductVariant?.quantity >
                              selectedProductVariant?.quantity_on_hand
                              ? selectedProductVariant?.quantity_on_hand
                              : it?.quantity + selectedProductVariant?.quantity,
                        };
                      } else {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity: it?.quantity,
                        };
                      }
                    }),
                  ],
                },
              })
            );
            toast.success("Product quantity updated in cart");
          } else {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    {
                      sku_id: selectedProduct?.sku_id,
                      unit: selectedProductVariant?.unit,
                      unit_type: selectedProductVariant?.unit_type,
                      quantity: selectedProductVariant?.quantity,
                    },
                    ...items.map((it) => {
                      return {
                        sku_id: it?.sku_id,
                        unit: it?.unit,
                        unit_type: it?.unit_type,
                        quantity: it?.quantity,
                      };
                    }),
                  ],
                },
              })
            );
            toast.success("Product Added to cart");
          }
        } else {
          dispatch(
            postInitializeQuotesApi({
              slug: slugId,
              quote: {
                items: [
                  {
                    sku_id: selectedProduct?.sku_id,
                    unit: selectedProductVariant?.unit,
                    unit_type: selectedProductVariant?.unit_type,
                    quantity: selectedProductVariant?.quantity,
                  },
                ],
              },
            })
          );
          toast.success("Product Added to cart");
        }
      }
      setTimeout(() => {
        setDisabledButtons((prevDisabledButtons) => ({
          ...prevDisabledButtons,
          [selectedProduct.id]: false,
        }));
      }, 1500);
    } catch (error) {
      // Handle error
      setDisabledButtons((prevDisabledButtons) => ({
        ...prevDisabledButtons,
        [selectedProduct.id]: false,
      }));
    }
  };
  /* End Add to cart */

  // Wishlist product
  const wishlistItems = useSelector(selectWishlist);
  const isInWishlist = (productId, variantId) => {

    if (!wishlistItems) {
      return false;
    }
    return wishlistItems.some(item => item.pId === productId && item.vId === variantId);
  };

  const incrementQuantityHandler = () => {
    if (selectedProductVariant?.quantity_on_hand > 0) {
      if (
        selectedProductVariant?.quantity <
        selectedProductVariant?.quantity_on_hand
      ) {
        
        setSelectedProductVariant({
          ...selectedProductVariant,
          quantity: selectedProductVariant?.quantity + 1,
        });
      } else {
        toast.warning("No more quantity in stock");
      }
    }
  };

  const decrementQuantityHandler = () => {
    if (selectedProductVariant?.quantity > 1) {
      setSelectedProductVariant({
        ...selectedProductVariant,
        quantity: selectedProductVariant?.quantity - 1,
      });
    } else {
      toast.warning("Minimum 1 quantity required");
    }
  };

  const filteredProducts = props.products.filter((item) => {
    if (isMedicalOnly) {
      // Apply medical filter
      return item.is_medical_only && item?.category_ids.includes(props.ids);
    }
    // No filter applied
    else {
      return item?.category_ids.includes(props.ids);
    }
  });

  const displayPrice = (item) => {
    const variant = item?.variants[0];

    if (variant) {
      const regularPrice = variant.price_cents
        ? variant.price_cents / 100
        : null;
      const discountedPrice = variant.discounted_price_cents
        ? variant.discounted_price_cents / 100
        : null;

      const regularMedPrice = variant.med_price_cents
        ? variant.med_price_cents / 100
        : null;
      const discountedMedPrice = variant.discounted_med_price_cents
        ? variant.discounted_med_price_cents / 100
        : null;

      if (regularMedPrice !== null && discountedMedPrice !== null) {
        // Product has both regular and discounted prices
        return (
          <div>
            <strike>${regularMedPrice.toFixed(2)}</strike>
            <span className="price">
              &nbsp;&nbsp;${discountedMedPrice.toFixed(2)}
            </span>
          </div>
        );
      } else if (regularPrice !== null && discountedPrice !== null) {
        // Product has both regular/medical and discounted prices
        return (
          <div>
            <strike>${regularPrice.toFixed(2)}</strike>
            <span className="price">
              &nbsp;&nbsp;${discountedPrice.toFixed(2)}
            </span>
          </div>
        );
      } else if (regularMedPrice !== null) {
        // Product has regular/medical price only
        return `$${regularMedPrice.toFixed(2)}`;
      } else if (regularPrice !== null) {
        // Product has regular price only
        return `$${regularPrice.toFixed(2)}`;
      } else {
        return "$0.00";
      }
    }

    return "$0.00";
  };

  //render price
  const renderPrice = () => {
    let price = 0;

    if (
      selectedProductVariant.med_price_cents ||
      selectedProductVariant.discounted_med_price_cents
    ) {
      price =
        selectedProductVariant.med_price_cents ||
        selectedProductVariant.discounted_med_price_cents;
    } else {
      price =
        selectedProductVariant.discounted_price_cents ||
        selectedProductVariant.price_cents;
    }

    // Assuming the quantity is stored in a variable called 'quantity'
    const totalPrice = (price / 100) * selectedProductVariant?.quantity;

    // Return the formatted price
    return totalPrice.toFixed(2); // Assuming you want to display the price with 2 decimal places
  };
  const productCard = document.querySelectorAll(
    ".product_cart:has(.addtocart_btn)"
  );
  const addCartBtn = document.querySelectorAll(".product_cart .addtocart_btn");
  const closeActive = document.querySelectorAll(
    ".product_cart:has(.addtocart_btn) .closeActive"
  );

  addCartBtn.forEach((e, ind) => {
    e.onclick = () => {
      if (activeProductCard !== productCard[ind]) {
        if (activeProductCard) {
          activeProductCard.classList.remove("active");
        }
        setActiveProductCard(productCard[ind]);
        productCard[ind].classList.add("active");
      }
    };
  });

  closeActive.forEach((e, ind) => {
    e.onclick = () => {
      productCard[ind].classList.remove("active");
      setActiveProductCard(null);
    };
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeProductCard && !activeProductCard.contains(event.target)) {
        activeProductCard.classList.remove("active");
        setActiveProductCard(null);
      }
    };

    const handleBodyClick = (event) => {
      handleClickOutside(event);
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [activeProductCard]);

  if (filteredProducts.length === 0) {
    return <p>{Constant.NO_PRODUCTS_FOUND}</p>;
  }

  return (
    <>
      <Slider {...sliderSettings}>
        {filteredProducts?.length > 0 ? (
          filteredProducts?.map((item, key) => {
            const discountIdForMedPrice =
              item?.variants[0]?.discount_id_for_med_price;
            const discountIdForPrice = item?.variants[0]?.discount_id_for_price;

            const discountForMedPrice =
              menuFeedData?.product_data?.discounts.find(
                (d) => d.id === discountIdForMedPrice
              );
            const discountForPrice = menuFeedData?.product_data?.discounts.find(
              (d) => d.id === discountIdForPrice
            );

            const discountNameForMedPrice = discountForMedPrice?.item_display_name;
            const discountNameForPrice = discountForPrice?.item_display_name;

            const chosenDiscountName =
              discountNameForMedPrice || discountNameForPrice;

            return (
              <React.Fragment key={item?.id}>
                <div className="card product_cart" key={item?.id}>
                  {chosenDiscountName && (
                    <div className="off">{chosenDiscountName}</div>
                  )}
                  <img
                    src={item?.product_image.lg}
                    alt="Product_Image"
                    onClick={() =>
                      navigate(
                        item?.brand_id
                          ? `/${slugify(item?.brand)}/${slugify(
                            item?.url_slug,
                          )}`
                          : `/${slugify(item?.url_slug)}`,
                        {
                          state: {
                            brands: item?.brand_id,
                            product_id: item?.product_id,
                          },
                        }
                      )
                    }
                  />
                  <h1
                    className="name"
                    onClick={() =>
                      navigate(
                        item?.brand_id
                          ? `/${slugify(item?.brand)}/${slugify(
                            item?.url_slug
                          )}`
                          : `/${slugify(item?.url_slug)}`,
                        {
                          state: {
                            brands: item?.brand_id,
                            product_id: item?.product_id,
                          },
                        }
                      )
                    }
                  >
                    {item?.variants[0]?.name}{" "}
                  </h1>
                  <div className="flower_types">
                    {item.flower_type && item.flower_type.length !== 0 && (
                      <React.Fragment>
                        <p className="indica">{item?.flower_type}</p>{" "}
                      </React.Fragment>
                    )}
                    <span>Dr. Jolly</span>
                    {/* <p>{chosenProductTypeName}{Constant.FLOWER}</p> */}
                  </div>
                  <div className="mt-2 mb-4 d-flex">
                    {item?.test_results?.thc?.current !== 0.0 && (
                      <span className="thc">
                        {Constant.THC} {item?.test_results?.thc?.current}
                        {item?.test_results?.thc?.unit_type}{" "}
                      </span>
                    )}
                    {item?.test_results?.cbd?.current !== 0.0 && (
                      <span className="cbd">
                        {Constant.CBD} {item?.test_results?.cbd?.current}
                        {item?.test_results?.cbd?.unit_type}
                      </span>
                    )}
                  </div>

                  <span className="regular-price">{displayPrice(item)}</span>
                  <div>
                    {item?.variants[0]?.quantity_on_hand <= 0 ? (
                      <p className="out-of-stock">{Constant.OUT_OF_STOCK}</p>
                    ) : (
                      <>
                        <button
                          className="btn btn-info addtocart_btn"
                          onClick={() => {
                            // addToCartitem(item)
                            setSelectedProduct(item);
                            setSelectedProductVariant(item?.variants?.[0]);
                          }}
                        // disabled={disabledButtons[item.id]}
                        >
                          {Constant.ADD_TO_CART}{" "}
                          <i className="fa fa-cart-shopping prd_dtl"></i>
                        </button>
                      </>
                    )}
                  </div>
                  <div className="back_product_cart">
                    {chosenDiscountName && (
                      <div className="off">{chosenDiscountName}</div>
                    )}

                    <div class="rectangle closeActive">
                      <i className="fa fa-angle-left"></i>
                    </div>

                    <img src={item?.product_image.lg} alt="Product_Image" />

                    <div className="quantity_dropdown">
                      {item?.variants?.length > 1 ? (
                        <>
                          <select
                            className="form-control form-select"
                            value={selectedProductVariant?.id}
                            onChange={(e) => handleVariantChange(e)}
                          >
                            {item?.variants?.map((vt) => (
                              <option key={vt?.id} value={vt?.id}>
                                {`${vt?.unit} ${vt?.unit_type}`}
                              </option>
                            ))}
                          </select>
                        </>
                      ) : (
                        <span className="form-control">{`${item?.variants[0]?.unit} ${item?.variants[0]?.unit_type}`}</span>
                      )}
                    </div>
                    <div className="back_quantity_button">
                      <div class="plus_minus_box">
                        <div class="plus_minus">
                          <span
                            className={`minus ${selectedProductVariant?.quantity <= 1
                              ? "disabled"
                              : ""
                              }`}
                            onClick={() => decrementQuantityHandler()}
                          >
                            -
                          </span>

                          <span className="value">
                            {selectedProductVariant?.quantity}
                          </span>

                          <span
                            className={`add ${selectedProductVariant?.quantity_on_hand <= 0
                              ? "disabled"
                              : ""
                              }`}
                            onClick={() => incrementQuantityHandler()}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="back_product_name">
                      <h1 className="name">{selectedProductVariant?.name} </h1>
                    </div>

                    <div
                      className="confirmbtn"
                      onClick={() => addToCartitem(selectedProductVariant)}
                    >
                      <button class="Checkout">confirm &nbsp;&nbsp;${renderPrice()} </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <div className="col-md-12 no_product">
            {" "}
            {Constant.NO_PRODUCTS_AVAILABLE}
          </div>
        )}
      </Slider>
    </>
  );
};

export default ProductSlider;


