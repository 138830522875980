import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { removeFromWishlist } from '../store/slice/wishlistSlice';
import { selectWishlist } from "../store/slice/wishlistSlice";
import { useNavigate } from 'react-router-dom';
import { addToCart } from "../store/slice/cartSlice";
import { toast } from "react-toastify";
import * as Constant from "../Constants";
import {
    incrementQuantity,
    decrementQuantity,
} from "../store/slice/MenuFeedAPISlice";

const WishlistPage = () => {
    const dispatch = useDispatch();
    const wishlist = useSelector((state) => state.wishlist);
    const productData = useSelector(state => state.menuFeed.info.product_data);

    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);

    const handleRemoveFromWishlist = (pId, vId) => {
        dispatch(removeFromWishlist({ pId, vId }));
    };
    // Function to find product details from the menu data
    const findProductDetails = (productId, variantId) => {

        const menuItem = productData.menu_items.find(item => item.product_id === productId);
        if (menuItem) {
            const variant = menuItem.variants.find(variant => variant.id === variantId);
            if (variant) {
                let price = 0;

                if (variant.discounted_med_price_cents != null && variant.med_price_cents != null) {
                    price = variant.discounted_med_price_cents / 100;
                } else if (variant.med_price_cents != null) {
                    price = variant.med_price_cents / 100;
                } else if (variant.discounted_price_cents != null) {
                    price = variant.discounted_price_cents / 100;
                } else {
                    price = variant.price_cents / 100;
                }

                return {
                    id: menuItem.id,
                    name: menuItem?.variants[0]?.name,
                    flower_type: menuItem.flower_type,
                    total_thc: menuItem.test_results?.total_thc?.current,
                    unit_type: menuItem.test_results?.total_thc?.unit_type,
                    image: menuItem.product_image.lg,
                    quantity_on_hand: menuItem?.variants[0]?.quantity_on_hand,
                    price: price,
                    // Add other necessary details from the product data
                };
            }
        }
        return null;
    };
    const addToCartitem = (item) => {
        // Dynamically add isInWishlist property when adding to cart
        const itemWithWishlistFlag = { ...item, isInWishlist: false }; // Initially, it's not in the wishlist
        dispatch(addToCart(itemWithWishlistFlag));

        // Remove the item from the wishlist when it is added to the cart
        handleRemoveFromWishlist(item.id);
    };


    const navigateHome = () => {
        // 👇️ navigate to /
        navigate('/');
    };

    const incrementQuantityHandler = (variant) => {
        if (variant.quantity < wishlist[0]?.variants[0]?.quantity_on_hand) {
            dispatch(incrementQuantity({ variant }));
        } else {
            toast.warning("No more quantity in stock");
        }
    };

    const decrementQuantityHandler = (variant) => {
        if (variant.quantity > 1) {
            dispatch(decrementQuantity({ variant }));
        } else {
            // Handle the case where the quantity is 1
            // Maybe show a message or disable the decrement functionality
            toast.warning("Minimum quantity reached");
        }
    };

    const increaseQuantity = () => {
        if (quantity < wishlist[0]?.variants[0]?.quantity_on_hand) {
            setQuantity(quantity + 1);
        } else {
            toast.error("Cannot add more items. Maximum quantity reached.");
        }
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        } else {
            toast.error("Quantity cannot be less than 1.");
        }
    };

    return (
        <>

            <div className="container cart_section">
                <div className="row mt-2 g-4 ">
                    <div className="col-md-12">
                        <h4>{Constant.MY_WISHLIST}</h4>
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-12">
                        {wishlist.length > 0 ? (
                            wishlist.map(item => {
                                const productDetails = findProductDetails(item.pId, item.vId);
                                if (productDetails) {
                                    return (

                                        <div key={`${item.pId}-${item.vId}`}>
                                            <div className="product_review_page">
                                                <div className="product_image_section">
                                                    <div class="off">5% Off</div>
                                                    <img
                                                        src={productDetails.image}
                                                        alt={productDetails.name}
                                                    />
                                                </div>
                                                <div className="wrap">
                                                    <div className="product-description">
                                                        <div className="product_review_details">
                                                            <h5 className="name">{productDetails.name}</h5>
                                                            <div className="d-flex">
                                                                {(productDetails.flower_type && productDetails.flower_type.length !== 0) && (
                                                                    <React.Fragment>
                                                                        <p className="indica">{productDetails.flower_type}</p>
                                                                    </React.Fragment>

                                                                )}
                                                                <span>Dr. Jolly's</span>
                                                            </div>
                                                            {/* <span>
                                                                {Constant.WEIGHT}{productDetails.variants[0].unit}
                                                                <span>{productDetails.variants[0].unit_type}</span>
                                                            </span> */}
                                                            <div className="total-section">
                                                                <div className="pMeta_wrap">
                                                                    <span className="item">
                                                                        {Constant.TOTAL_THC} : {productDetails.total_thc}{" "}
                                                                        {productDetails.unit_type}
                                                                    </span>

                                                                </div>
                                                                <div className="img-section">
                                                                    <img src={window.location.origin + "/images/strain-data.png"} alt="dd"></img>
                                                                </div>
                                                            </div>
                                                            <div className="plus_minus_box">
                                                                {productDetails.quantity_on_hand <= 0 ? (
                                                                    <p className="out-of-stock">{Constant.OUT_OF_STOCK}</p>
                                                                ) : (

                                                                    <>
                                                                        <div className="plus_minus">
                                                                            <span className="minus" onClick={decreaseQuantity}>
                                                                                -
                                                                            </span>
                                                                            <span className="value">{quantity}</span>
                                                                            <span className="add" onClick={increaseQuantity}>
                                                                                +
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product_total_section">
                                                        <h5>
                                                            ${productDetails.price}
                                                        </h5>
                                                        <div className="button-group">
                                                            <button className="btn apply1" onClick={() => handleRemoveFromWishlist(item.pId, item.vId)}>
                                                                <i className="fa fa-trash wish"></i>&nbsp;&nbsp; {Constant.REMOVE}
                                                            </button>
                                                            <button className="btn btn-info Wishlistbtn" onClick={() => addToCartitem(productDetails)}>
                                                                <i className="fa fa-cart-shopping prd_dtl"></i>&nbsp;&nbsp; {Constant.MOVE_TO_CART}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mob_action">
                                                    <div className="select_box">
                                                        <p className="quantity">Weight</p>
                                                        <select className="form-control form-select" >
                                                            <option>10g</option>
                                                            <option>20g</option>
                                                        </select>
                                                    </div>

                                                    <div className="plus_minus_box">
                                                        <p className="quantity quantityP">Quantity</p>
                                                        <div className="plus_minus">
                                                            <span className="add">+</span>
                                                            <span className="value">1</span>
                                                            <span className="minus">-</span>
                                                        </div>
                                                    </div>
                                                    <div className="button-group">
                                                        <button className="btn btn-info Wishlistbtn"><i className="fa fa-cart-shopping prd_dtl"></i> Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                return null
                            })
                        ) : (
                            <div className="empty_cart text-center container">
                                <img src="./images/Group 778.png" />
                                <button className="btn btn-info mt-4 w-25" onClick={navigateHome}>
                                    {Constant.RETURN_TO_SHOPPING}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </>
    );
};

export default WishlistPage;


