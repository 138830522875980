export const fetchBrandId = (brandName, menuFeedData) => {
    let brandId;

    const filteredBrandArr = menuFeedData?.product_data?.brands.filter((brand) => {
        return brandName?.toLowerCase() === brand?.name.toLowerCase()
    });

    if (filteredBrandArr && filteredBrandArr.length >= 0) {
        brandId = filteredBrandArr[0]?.id;
    }
    return brandId;
}

export const fetchProductId = (productName, menuFeedData) => {
    let productId;
   
    const filteredProductArr = menuFeedData?.product_data?.menu_items.filter((product) => {
        return productName.toLowerCase() === product?.url_slug.toLowerCase();
    });

    if (filteredProductArr && filteredProductArr.length >= 0) {
        productId = filteredProductArr[0]?.product_id;
    }
    return productId;
}