import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMerchantInfo } from "../store/actions";
import { fetchMenuFeedApi } from "../store/MenuFeedaction";
import { fetchVenueInfo } from "../store/slice/venueSlice";
import { changeVenue } from '../store/slice/merchantSlice';
import { fetchContentData } from "../store/ContentAction";
import { getSdpData } from "../store/SdpAction.js";
import { getDiscountData } from "../store/discountAction.js";
import { useLocation } from 'react-router-dom';
import * as Constant from "../Constants";
import { headerTextColor } from '../store/slice/ContentSlice.js';

const StoreLocation = ({ onVenueChange }) => {
  const headerTextColorValue = useSelector(headerTextColor);
  useEffect(() => {
    document.documentElement.style.setProperty('--headertext-color', headerTextColorValue);
  }, [headerTextColorValue]);
  const dispatch = useDispatch();
  const { info, loading, error } = useSelector((state) => state.merchant || {});
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );

  const [selectedVenue, setSelectedVenue] = useState(
    localStorage.getItem("selectedVenue") || slugId || ""
  );
  const location = useLocation();
  // Fetch merchant info on component mount
  useEffect(() => {
    dispatch(fetchMerchantInfo(window.posabitmenuwidget.config.api_key));
  }, [dispatch]);
  // Set localStorage before handling venue change
  useEffect(() => {
    localStorage.setItem("selectedVenue", selectedVenue ? selectedVenue : slugId);
  }, [selectedVenue, slugId]);

  const handleVenueChange = (event) => {
    const selectedVenueSlug = event.target.value.toString();
    setSelectedVenue(selectedVenueSlug);
    onVenueChange(selectedVenueSlug);
    localStorage.setItem("selectedVenue", selectedVenueSlug ? selectedVenueSlug : slugId);
    dispatch(fetchMenuFeedApi(event.target.value));
    dispatch(changeVenue(event.target.value));
    dispatch(fetchVenueInfo(event.target.value));
    dispatch(fetchContentData(event.target.value));
    dispatch(getSdpData(event.target.value));
    dispatch(getDiscountData(event.target.value));

  };
  const isHomepage = location.pathname === "/";
  if (loading) {
    return <div className="loading">{Constant.LOADING}</div>;
  }

  if (error) {
    return <div>{Constant.ERROR} {error}</div>;
  }

  return (
    <>
      {isHomepage && (
        <>
          <div>
            <i className="fa fa-location"></i>
            <select className="location_static" onChange={handleVenueChange} value={selectedVenue || ""}>
              {info?.merchant?.venues.map((venue) => (
                <>
                  <option key={venue?.slug} value={venue?.slug} className="text-dark">
                    {venue?.name}
                  </option>
                  <option value="fa" className="faf fa" disabled>
                    {venue.address},{venue.city}
                  </option>
                </>
              ))}
            </select>
          </div>
        </>
      )}
      {!isHomepage && (
        <>
          <i className="fa fa-location"></i>
           <span className="location_static "> {info?.merchant?.venues.find(venue => venue?.slug === selectedVenue)?.name}</span>
        </>
      )}
    </>
  );
};

export default StoreLocation;
