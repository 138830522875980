import React from "react"
import Banner from "./Banner.js";
import CategoryWithProduct from "./CategoryWithProduct.js";
const Home = (props) => {
  return (
    <>
      <Banner />
      <CategoryWithProduct /> 
    </>
  )
}

export default Home