import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Home from "./components/Home.js";
import CategoryProducts from "./components/ProductCategories";
import ProductDetail from "./components/ProductDetail.js";
import FilterProduct from "./components/FilterProduct.js";
import WishlistPage from "./components/Wishlist.js";
import Cart from "./components/Cart.js";
import AllProducts from "./components/AllProducts.js";
import CheckOut from "./components/CheckOut.js";
import OrderThankyouPage from "./components/OrderThankyouPage.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { Provider } from "react-redux";
import {store, persistor} from "./store/index";
import { PersistGate } from "redux-persist/integration/react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/:brands?/:product_id",
        element: <ProductDetail />
      },
      {
        path: "/category/:categoryId",
        element: <CategoryProducts />
      },
      {
        path: "/cart",
        element: <Cart />
      },
      {
        path: "/products",
        element: <AllProducts />
      },
      {
        path: "/wishlist",
        element: <WishlistPage />
      },
      {
        path: "/checkout",
        element: <CheckOut />
      },
      {
        path: "/thank-you",
        element: <OrderThankyouPage />
      },
      {
        path: "/filterproduct",
        element: <FilterProduct />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);