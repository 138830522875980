import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Product from "./Product";
import { selectMenuFeed } from "../store/slice/MenuFeedAPISlice";
import { fetchMenuFeedApi } from "../store/MenuFeedaction";
import * as Constant from "../Constants";

const AllProducts = (props) => {
  const dispatch = useDispatch();
  const menuFeedDatas = useSelector(selectMenuFeed);
  const loader = useSelector((state) => state.menuFeed.loading);
  const [products, setProducts] = useState([]);
  const [queryParameters] = useSearchParams();
  const searchedString = queryParameters.get("search");
  const isMedicalOnly = localStorage.getItem("menuType") === "Recreational" ? false : true
  

  const searchInArray = (nameFilter, dataArray, key) => {
    return dataArray.filter((value) => {
      return value.name?.toLowerCase() === nameFilter?.toLowerCase();
    }).map((item) => {
      return item[key];
    });
  };
  
  const searchByBrand = (nameFilter) => {
    return searchInArray(nameFilter, menuFeedDatas.product_data.brands, 'id');
  };
  
  const searchByProductType = (nameFilter) => {
    return searchInArray(nameFilter, menuFeedDatas.product_data.product_types, 'id');
  };
  
  const searchByStrains = (nameFilter) => {
    return searchInArray(nameFilter, menuFeedDatas.product_data.strains, 'id');
  };
  
  const searchByTerpenes = (nameFilter) => {
    return searchInArray(nameFilter, menuFeedDatas.product_data.terpenes, 'id');
  };
  
  const searchByeffects = (nameFilter) => {
    return searchInArray(nameFilter, menuFeedDatas.product_data.effects, 'id');
  };
  
  const searchByflavors = (nameFilter) => {
    return searchInArray(nameFilter, menuFeedDatas.product_data.flavors, 'id');
  };
  
  const medicalState = useSelector(store=>store?.menuFeed?.isMedicalOnly)

  useEffect(() => {

    const filterProducts = () => {
      if (
        !menuFeedDatas ||
        !menuFeedDatas.product_data ||
        !menuFeedDatas.product_data.menu_items
      ) {
        return;
      }
      const filteredProducts = menuFeedDatas.product_data.menu_items.filter((product) => {

        const nameFilter = searchedString;
        const brandSearch = searchByBrand(nameFilter);
        const productTypeSearch = searchByProductType(nameFilter);
        const strainsSearch = searchByStrains(nameFilter);
        const terpenesSearch = searchByTerpenes(nameFilter);
        const effectsSearch = searchByeffects(nameFilter);
        const flavorsSearch = searchByflavors(nameFilter);


        const passNameFilter = nameFilter && (product.variants[0].name && product.variants[0].name.toLowerCase().includes(nameFilter.toLowerCase()));
        const passFlowerType = nameFilter && (product?.flower_type && product?.flower_type.toLowerCase().includes(nameFilter.toLowerCase()));
        const passBrandFilter = brandSearch &&  brandSearch.includes(product.brand_id);
        const passProductTypeFilter = productTypeSearch &&  productTypeSearch.includes(product.product_type_id);
        const passstrainsFilter = strainsSearch &&  strainsSearch.includes(product.strain_id);
        // const passterpenesFilter = terpenesSearch &&  terpenesSearch.includes(product.terpene_ids[0]);
        const passterpenesFilter =
          product.terpene_ids &&
          product.terpene_ids.some(
            (terpeneId) =>
            searchByTerpenes(nameFilter).includes(terpeneId)
          );
          
        // const passeffectsFilter = effectsSearch &&  effectsSearch.includes(product.effect_ids[0]);
        const passeffectsFilter =
          product.effect_ids &&
          product.effect_ids.some(
            (effectId) =>
              searchByeffects(nameFilter).includes(effectId)
          );

        const passflavorsFilter =
          product.flavor_ids &&
          product.flavor_ids.some(
            (flavorId) =>
            searchByflavors(nameFilter).includes(flavorId)
          );
        // const passflavorsFilter = flavorsSearch &&  flavorsSearch.includes(product.flavor_ids[0]);

       
        return passNameFilter || passBrandFilter || passProductTypeFilter || passFlowerType || passstrainsFilter || passterpenesFilter || passeffectsFilter || passflavorsFilter;
      })
      .filter(item => {
        if(item.is_medical_only == isMedicalOnly){
          return item
        }
    });

      setProducts(filteredProducts);
    };
    filterProducts();
    
  }, [menuFeedDatas, searchedString, queryParameters, isMedicalOnly, medicalState]);
  
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlugId = slugId;
  useEffect(() => {
    const storedSlugId = localStorage.getItem("selectedVenue");
   
    const slugId = storedSlugId || defaultSlugId;
    dispatch(fetchMenuFeedApi(slugId));
  }, [dispatch]);

  if (!menuFeedDatas || !menuFeedDatas.product_data || !menuFeedDatas.product_data.menu_items) {
    return <div>{Constant.LOADING}</div>;
  }

  return (
    <>
      <div className="row m-0">
        {loader ? (
          <div className="text-center">
            <img src="https://media.tenor.com/wpSo-8CrXqUAAAAi/loading-loading-forever.gif" style={{ width: "100px" }} alt="loader" />
          </div>
        ) : (
          <Product products={products} />
        )}
      </div>
    </>
  );
};

export default AllProducts;
