import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchQuoteCartInfoApi = createAsyncThunk(
  "getQuote/fetchInfo",
  async (merchantToken, payload) => {
    try {
      const response = await fetch("https://qa-ecomm-app.posabit.com/mcx/oregon_tops/venue/1128/v1/quotes/3313-1698772410-878fd07a", {
        headers: {
          merchantToken: window.posabitmenuwidget.config.api_key,
          contentType: "application/json",
        //   authorization: 'Bearer 1vm1ax-tIxpocYteXD1FWA',
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch get Cart info info");
      }

      const data = await response.json();

      return data;
    } catch (error) {

      throw new Error(error.message);
    }
  }
);