import React, { useEffect, useState } from "react";
import slugify from "react-slugify";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectMenuFeed } from "../store/slice/MenuFeedAPISlice";
import { useLocation } from "react-router-dom";
import * as Constant from "../Constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addToWishlist,
  removeFromWishlist,
} from "../store/slice/wishlistSlice";
import {
  postInitializeQuotesApi,
  putQuotesUpdateItemsApi,
} from "../store/InitializeQuotesPostAPI";
import { selectWishlist } from "../store/slice/wishlistSlice";

const FilterProduct = () => {
  const [disabledButtons, setDisabledButtons] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({})
  const [selectedProductVariant, setSelectedProductVariant] = useState({})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuFeedData = useSelector(selectMenuFeed);
  const [activeProductCard, setActiveProductCard] = useState(null);
  const [currentProSelectedVariant, setCurrentProSelectedVariant] = useState(
    {}
  );
  const [filteredProduct, setFilteredProducts] = useState([]);
  let {
    priceRange,
    selectedBrand,
    selectedCategories,
    selectedSubcategoryId,
    selectedType,
    sortOrder,
    selectedTerpenes,
    selectedFlavors,
    selectedEffects,
    selectedStrains,
    selectedWeight,
  } = useLocation().state || {};
  const [sortingOption, setSortingOption] = useState("");
  const isMedicalOnly = localStorage.getItem("menuType") === "Recreational" ? false : true

  // display price according to regular/medical and discounted prices
  const displayPrice = (item) => {
    const variant = item?.variants[0];

    if (variant) {
      const regularPrice = variant.price_cents
        ? variant.price_cents / 100
        : null;
      const discountedPrice = variant.discounted_price_cents
        ? variant.discounted_price_cents / 100
        : null;

      const regularMedPrice = variant.med_price_cents
        ? variant.med_price_cents / 100
        : null;
      const discountedMedPrice = variant.discounted_med_price_cents
        ? variant.discounted_med_price_cents / 100
        : null;

      if (regularMedPrice !== null && discountedMedPrice !== null) {
        // Product has both regular and discounted prices
        return (
          <div>
            <strike>{Constant.DOLLAR}{regularMedPrice.toFixed(2)}</strike>
            <span className="price">
              &nbsp;&nbsp;{Constant.DOLLAR}{discountedMedPrice.toFixed(2)}
            </span>
          </div>
        );
      } else if (regularPrice !== null && discountedPrice !== null) {
        // Product has both regular/medical and discounted prices
        return (
          <div>
            <strike>{Constant.DOLLAR}{regularPrice.toFixed(2)}</strike>
            <span className="price">
              &nbsp;&nbsp;{Constant.DOLLAR}{discountedPrice.toFixed(2)}
            </span>
          </div>
        );
      } else if (regularMedPrice !== null) {
        // Product has regular/medical price only
        return `$${regularMedPrice.toFixed(2)}`;
      } else if (regularPrice !== null) {
        // Product has regular price only
        return `$${regularPrice.toFixed(2)}`;
      } else {
        return "N/A";
      }
    }

    return "N/A"; // Handle case where variant is null or undefined
  };

  // add to Wishlist 
  const addToWishlistHandler = (item) => {
    const updatedItem = { ...item, isInWishlist: !item.isInWishlist };
    dispatch(
      item.isInWishlist
        ? removeFromWishlist(updatedItem)
        : addToWishlist(updatedItem)
    );
  };
  // Wishlist product
  const wishlistItems = useSelector(selectWishlist);
  const isInWishlist = (productId, variantId) => {

    if (!wishlistItems) {
      return false;
    }
    return wishlistItems.some(item => item.pId === productId && item.vId === variantId);
  };

  const medicalState = useSelector(store => store?.menuFeed?.isMedicalOnly)

  // filter product
  useEffect(() => {
    const filtered = menuFeedData.product_data.menu_items.filter((item) => {

      let itemDiscountedPrice;

      if (item.variants[0].discounted_price_cents) {
        itemDiscountedPrice = item.variants[0].discounted_price_cents / 100;
      } else if (item.variants[0].discounted_med_price_cents) {
        itemDiscountedPrice = item.variants[0].discounted_med_price_cents / 100;
      } else if (item.variants[0].med_price_cents) {
        itemDiscountedPrice = item.variants[0].med_price_cents / 100;
      } else {
        itemDiscountedPrice = item.variants[0].price_cents / 100;
      }

      const effectsMatches = !selectedEffects || selectedEffects.length === 0 || selectedEffects.some(id => item.effect_ids.includes(id));
      const flavourMatches = !selectedFlavors || selectedFlavors.length === 0 || selectedFlavors.some(id => item.flavor_ids.includes(id));
      const TerpenesMatches = !selectedTerpenes || selectedTerpenes.length === 0 || selectedTerpenes.some(id => item.terpene_ids.includes(id));
      const strainsMatches = !selectedStrains || selectedStrains.length === 0 || selectedStrains.includes(item.strain_id);
      const typeMatches = !selectedType || selectedType.length === 0 || selectedType.includes(item.product_type_id);
      const brandMatches = !selectedBrand || selectedBrand.length === 0 || selectedBrand.includes(item.brand_id);
  
      const catOrSubCat = (
        (selectedCategories && selectedCategories.length > 0) &&
        (selectedSubcategoryId && selectedSubcategoryId.length > 0)
      ) ? (
        selectedCategories.some(categoryId => item.category_ids.includes(categoryId)) ||
        selectedSubcategoryId.some(subcategoryId => item.subcategory_ids.includes(subcategoryId))
      ) : (
        (selectedCategories && selectedCategories.length > 0) ?
        selectedCategories.some(categoryId => item.category_ids.includes(categoryId)) :
        (selectedSubcategoryId && selectedSubcategoryId.length > 0) ?
        selectedSubcategoryId.some(subcategoryId => item.subcategory_ids.includes(subcategoryId)) :
        true
      );
      
      
      const weightMatches = !selectedWeight || selectedWeight.length === 0 || (
        Array.isArray(selectedWeight) && selectedWeight.length > 0 &&
        item.variants && item.variants.length > 0 &&  
        item.variants.some((variant) => 
          variant.display_weight && selectedWeight.includes(variant.display_weight)
        )
      );
      
      return weightMatches && brandMatches && strainsMatches && TerpenesMatches && flavourMatches && effectsMatches && typeMatches && 
      priceRange && priceRange.length >= 1 && itemDiscountedPrice >= priceRange[0] && itemDiscountedPrice <= priceRange[1] && catOrSubCat
    })
    .filter(item => {
      if (item.is_medical_only === isMedicalOnly) {
        return item;
      }
    });
    const sorted = filtered.sort((a, b) => {
      const nameA = a.variants[0].name.toUpperCase();
      const nameB = b.variants[0].name.toUpperCase();
      if (sortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else if (sortOrder === "desc") {
        return nameB.localeCompare(nameA);
      }

      return 0;
    });

    setFilteredProducts(sorted);
  }, [
    menuFeedData,
    priceRange,
    selectedBrand,
    selectedCategories,
    selectedSubcategoryId,
    selectedTerpenes,
    selectedType,
    selectedStrains,
    selectedFlavors,
    selectedEffects,
    sortOrder,
    selectedWeight,
    isMedicalOnly,
    medicalState
  ]);

  // sorting product
  const handleSortingChange = (e) => {
    const selectedSortOption = e.target.value;
    setSortingOption(selectedSortOption);

    let sortedProducts = [...filteredProduct];

    switch (selectedSortOption) {
      case "price-asc":
        sortedProducts.sort((a, b) => {
          const priceA =
            a.variants[0].discounted_med_price_cents ||
            a.variants[0].discounted_price_cents ||
            a.variants[0].med_price_cents ||
            a.variants[0].price_cents ||
            0;
          const priceB =
            b.variants[0].discounted_med_price_cents ||
            b.variants[0].discounted_price_cents ||
            b.variants[0].med_price_cents ||
            b.variants[0].price_cents ||
            0;
          return priceA - priceB;
        });
        break;
      case "price-desc":
        sortedProducts.sort((a, b) => {
          const priceA =
            a.variants[0].discounted_med_price_cents ||
            a.variants[0].discounted_price_cents ||
            a.variants[0].med_price_cents ||
            a.variants[0].price_cents ||
            0;
          const priceB =
            b.variants[0].discounted_med_price_cents ||
            b.variants[0].discounted_price_cents ||
            b.variants[0].med_price_cents ||
            b.variants[0].price_cents ||
            0;
          return priceB - priceA;
        });
        break;
      case "name-asc":
        sortedProducts.sort((a, b) => {
          const nameA = a.variants[0].name.toUpperCase();
          const nameB = b.variants[0].name.toUpperCase();
          return nameA.localeCompare(nameB);
        });
        break;
      case "name-desc":
        sortedProducts.sort((a, b) => {
          const nameA = a.variants[0].name.toUpperCase();
          const nameB = b.variants[0].name.toUpperCase();
          return nameB.localeCompare(nameA);
        });
        break;
      case "thc-low-high":
        sortedProducts.sort((a, b) => {
          const thcA = a.test_results.thc.current;
          const thcB = b.test_results.thc.current;
          return thcA - thcB;
        });
        break;
      case "thc-high-low":
        sortedProducts.sort((a, b) => {
          const thcA = a.test_results.thc.current;
          const thcB = b.test_results.thc.current;
          return thcB - thcA;
        });
        break;
      case "cbd-low-high":
        sortedProducts.sort((a, b) => {
          const thcA = a.test_results.cbd.current;
          const thcB = b.test_results.cbd.current;
          return thcA - thcB;
        });
        break;
      case "cbd-high-low":
        sortedProducts.sort((a, b) => {
          const thcA = a.test_results.cbd.current;
          const thcB = b.test_results.cbd.current;
          return thcB - thcA;
        });
        break;
      case "popularity-high-low":
        sortedProducts.sort((a, b) => {
          const popularityA = a.popularity || 9999; // Consider null popularity as least popular
          const popularityB = b.popularity || 9999;
          return popularityA - popularityB;
        });
        break;

      case "popularity-low-high":
        sortedProducts.sort((a, b) => {
          const popularityA = a.popularity || 9999; // Consider null popularity as least popular
          const popularityB = b.popularity || 9999;

          return popularityB - popularityA;
        });
        break;
      default:
        break;
    }

    setFilteredProducts(sortedProducts); // Update the state with sorted products
  };


  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlug = slugId;
  const reference_no = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.reference_no
  );

  const items = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.items
  );

  // Variant chnage
  useEffect(() => {
    if (Object.keys(selectedProduct).length !== 0) {
      setCurrentProSelectedVariant(
        menuFeedData?.product_data?.menu_items
          ?.filter((item) => {
            if (item.product_id == selectedProduct.product_id) {
              return item;
            }
          })?.[0]
          .variants?.filter((va) => va?.selected)[0]
      );
    }
  }, [menuFeedData]);

  const handleVariantChange = (e) => {
    setSelectedProductVariant(selectedProduct.variants.filter(vr => {
      if (vr.id === e.target.value) {
        return vr
      }
    })?.[0])
  };

  // add to cart
  const addToCartitem = async (selectedProduct) => {
    // Disable the button
    setDisabledButtons((prevDisabledButtons) => ({
      ...prevDisabledButtons,
      [selectedProduct.id]: true,
    }));
    try {

      const slugId = localStorage.getItem("selectedVenue") || defaultSlug;
      const existingCartItem = items?.find((it) =>
        it.sku_id === selectedProduct?.sku_id &&
        it.unit === selectedProductVariant?.unit &&
        it.unit_type === selectedProductVariant?.unit_type
      );

      if (existingCartItem && existingCartItem.quantity === selectedProductVariant?.quantity_on_hand) {

        toast.warning("You have reached the maximum quantity");
      } else {
        if (reference_no) {
          const found = items.filter((it) => {
            if (
              it.sku_id === selectedProduct?.sku_id &&
              it.unit === selectedProductVariant?.unit &&
              it.unit_type === selectedProductVariant?.unit_type
            ) {
              return it;
            }
          });
          if (found.length > 0) {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    ...items.map((it) => {
                      if (
                        it.sku_id === selectedProduct?.sku_id &&
                        it.unit === selectedProductVariant?.unit &&
                        it.unit_type === selectedProductVariant?.unit_type
                      ) {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity:
                            it?.quantity + selectedProductVariant?.quantity >
                              selectedProductVariant?.quantity_on_hand
                              ? selectedProductVariant?.quantity_on_hand
                              : it?.quantity + selectedProductVariant?.quantity,
                        };
                      } else {
                        return {
                          sku_id: it?.sku_id,
                          unit: it?.unit,
                          unit_type: it?.unit_type,
                          quantity: it?.quantity,
                        };
                      }
                    }),
                  ],
                },
              })
            );
            toast.success("Product quantity updated in cart");

          } else {
            dispatch(
              putQuotesUpdateItemsApi({
                slug: slugId,
                reference_no,
                quote: {
                  items: [
                    {
                      sku_id: selectedProduct?.sku_id,
                      unit: selectedProductVariant?.unit,
                      unit_type: selectedProductVariant?.unit_type,
                      quantity: selectedProductVariant?.quantity,
                    },
                    ...items.map((it) => {
                      return {
                        sku_id: it?.sku_id,
                        unit: it?.unit,
                        unit_type: it?.unit_type,
                        quantity: it?.quantity,
                      };
                    }),
                  ],
                },
              })
            );
            toast.success("Product Added to cart");
          }
        } else {
          dispatch(
            postInitializeQuotesApi({
              slug: slugId,
              quote: {
                items: [
                  {
                    sku_id: selectedProduct?.sku_id,
                    unit: selectedProductVariant?.unit,
                    unit_type: selectedProductVariant?.unit_type,
                    quantity: selectedProductVariant?.quantity,
                  },
                ],
              },
            })
          );
          toast.success("Product Added to cart");
        }

      }
      setTimeout(() => {
        setDisabledButtons((prevDisabledButtons) => ({
          ...prevDisabledButtons,
          [selectedProduct.id]: false,
        }));
      }, 1500);
    } catch (error) {
      // Handle error
      setDisabledButtons((prevDisabledButtons) => ({
        ...prevDisabledButtons,
        [selectedProduct.id]: false,
      }));
    }

  };
  // end add to cart

  // increment & decrement product
  const incrementQuantityHandler = () => {
    if (selectedProductVariant?.quantity_on_hand > 0) {
      if (selectedProductVariant?.quantity < selectedProductVariant?.quantity_on_hand) {
        setSelectedProductVariant({ ...selectedProductVariant, quantity: selectedProductVariant?.quantity + 1 })
      } else {
        toast.warning("No more quantity in stock");
      }
    }
  };


  const decrementQuantityHandler = () => {
    if (selectedProductVariant?.quantity > 1) {
      setSelectedProductVariant({ ...selectedProductVariant, quantity: selectedProductVariant?.quantity - 1 })
    } else {
      toast.warning("Minimum 1 quantity required");
    }
  };
  // End increment & decrement product

  //render price
  const renderPrice = () => {
    let price = 0;

    if (selectedProductVariant.med_price_cents || selectedProductVariant.discounted_med_price_cents) {
      price = selectedProductVariant.med_price_cents || selectedProductVariant.discounted_med_price_cents;
    } else {
      price = selectedProductVariant.discounted_price_cents || selectedProductVariant.price_cents;
    }

    // Assuming the quantity is stored in a variable called 'quantity'
    const totalPrice = (price / 100) * selectedProductVariant?.quantity;

    // Return the formatted price
    return totalPrice.toFixed(2); // Assuming you want to display the price with 2 decimal places
  };

  const productCard = document.querySelectorAll('.product_cart:has(.addtocart_btn)');
  const addCartBtn = document.querySelectorAll('.product_cart .addtocart_btn');
  const closeActive = document.querySelectorAll('.product_cart:has(.addtocart_btn) .closeActive');

  addCartBtn.forEach((e, ind) => {
    e.onclick = () => {
      if (activeProductCard !== productCard[ind]) {
        if (activeProductCard) {
          activeProductCard.classList.remove("active");
        }
        setActiveProductCard(productCard[ind]);
        productCard[ind].classList.add("active");
      }
    };
  });

  closeActive.forEach((e, ind) => {
    e.onclick = () => {
      productCard[ind].classList.remove("active");
      setActiveProductCard(null);
    }
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeProductCard && !activeProductCard.contains(event.target)) {
        activeProductCard.classList.remove("active");
        setActiveProductCard(null);
      }
    };

    const handleBodyClick = (event) => {
      handleClickOutside(event);
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [activeProductCard]);

  return (
    <>
      <section id="product_section" className="seacrh_product">
        
        <div className="row d-flex align-items-end justify-content-end">
          <div className="col-md-2 sorting-dropdown ">
            <select className="form-select" onChange={handleSortingChange}>
              <option value="">{Constant.SORT_BY}</option>
              <option value="name-asc">{Constant.A_Z}</option>
              <option value="price-asc">{Constant.PRICE_LOW_TO_HIGH}</option>
              <option value="price-desc"> {Constant.PRICE_HIGH_TO_LOW}</option>
              <option value="thc-low-high">{Constant.THC_LOW_TO_HIGH}</option>
              <option value="thc-high-low">{Constant.THC_HIGH_TO_LOW}</option>
              <option value="cbd-low-high"> {Constant.CBD_LOW_TO_HIGH}</option>
              <option value="cbd-high-low"> {Constant.CBD_HIGH_TO_LOW}</option>
              <option value="popularity-high-low">Most Popular</option>
              <option value="popularity-low-high">Least Popular</option>
            </select>
          </div>
        </div>

        <div className="row">
          {filteredProduct?.length > 0 ? (
            filteredProduct?.map((product, key) => {
            
              const discountIdForMedPrice =
                product.variants[0].discount_id_for_med_price;
              const discountIdForPrice =
                product.variants[0].discount_id_for_price;

              const discountForMedPrice =
                menuFeedData?.product_data?.discounts.find(
                  (d) => d.id === discountIdForMedPrice
                );
              const discountForPrice =
                menuFeedData?.product_data?.discounts.find(
                  (d) => d.id === discountIdForPrice
                );

              const discountNameForMedPrice = discountForMedPrice?.item_display_name;
              const discountNameForPrice = discountForPrice?.item_display_name;

              const chosenDiscountName =
                discountNameForMedPrice || discountNameForPrice;
              return (
                <React.Fragment key={product?.id}>
                  <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-3">
                    <div key={product.id}>
                      <div className="card  product_cart">
                        {chosenDiscountName && (
                          <div className="off">{chosenDiscountName}</div>
                        )}

                        <img
                          src={product?.product_image.lg}
                          alt="Product_Image"
                          onClick={() => navigate(product?.brand_id
                            ? `/${slugify(product?.brand)}/${slugify(product?.variants[0]?.name)}`
                            : `/${slugify(product?.variants[0]?.name)}`, {
                            state: {
                              brands: product?.brand_id,
                              product_id: product?.product_id,
                            }
                          })}
                        />

                        <h1
                          className="name"
                          onClick={() => navigate(product?.brand_id
                            ? `/${slugify(product?.brand)}/${slugify(product?.variants[0]?.name)}`
                            : `/${slugify(product?.variants[0]?.name)}`, {
                            state: {
                              brands: product?.brand_id,
                              product_id: product?.product_id,
                            }
                          })}
                        >
                          {product?.variants[0].name}{" "}
                        </h1>

                        <div className="flower_types">
                          {(product.flower_type && product.flower_type.length !== 0) && (
                            <React.Fragment>
                              <p className="indica">{product?.flower_type}</p>{" "}
                            </React.Fragment>
                          )}
                          <span>Dr. Jolly</span>
                          {/* <p>{chosenProductTypeName}{Constant.FLOWER}</p> */}
                        </div>
                        <div className="mt-2 mb-4 d-flex">
                          {product?.test_results?.thc?.current !== 0.0 && (
                            <span className="thc">
                              {Constant.THC} {product?.test_results?.thc?.current}
                              {product?.test_results?.thc?.unit_type}{" "}
                            </span>
                          )}
                          {product?.test_results?.cbd?.current !== 0.0 && (
                            <span className="cbd">
                              {Constant.CBD} {product?.test_results?.cbd?.current}
                              {product?.test_results?.cbd?.unit_type}
                            </span>
                          )}
                        </div>
                        <span className="regular-price">{displayPrice(product)}</span>
                        <div>

                          {product?.variants[0]?.quantity_on_hand <= 0 ? (
                            <p className="out-of-stock">{Constant.OUT_OF_STOCK}</p>
                          ) : (

                            <>
                              <button
                                className="btn btn-info addtocart_btn"
                                onClick={() => {
                                  // addToCartitem(item)
                                  setSelectedProduct(product)
                                  setSelectedProductVariant(product?.variants?.[0])
                                }}
                              // disabled={disabledButtons[item.id]}
                              >
                                {Constant.ADD_TO_CART} <i className="fa fa-cart-shopping prd_dtl"></i>
                              </button>
                            </>
                          )}

                        </div>
                        <div className="back_product_cart">
                          {chosenDiscountName && (
                            <div className="off">{chosenDiscountName}</div>
                          )}

                          <div class="rectangle closeActive"><i className="fa fa-angle-left"></i></div>

                          <img src={product?.product_image.lg} alt="Product_Image" />

                          <div className="quantity_dropdown">

                            {product?.variants?.length > 1 ? (
                              <>
                                <select
                                  className="form-control form-select"
                                  value={selectedProductVariant?.id}
                                  onChange={(e) => handleVariantChange(e)}
                                >
                                  {product?.variants?.map((vt) => (
                                    <option key={vt?.id} value={vt?.id}>
                                      {`${vt?.unit} ${vt?.unit_type}`}
                                    </option>
                                  ))}
                                </select>
                              </>
                            ) : (
                              <span className="form-control">{`${product?.variants[0]?.unit} ${product?.variants[0]?.unit_type}`}</span>
                            )}

                          </div>
                          <div className="back_quantity_button">
                            <div class="plus_minus_box">
                              <div class="plus_minus">
                                <span
                                  className={`minus ${selectedProductVariant?.quantity <= 1 ? "disabled" : ""}`}
                                  onClick={() => decrementQuantityHandler()}
                                >
                                  -
                                </span>

                                <span className="value">
                                  {selectedProductVariant?.quantity}
                                </span>

                                <span
                                  className={`add ${selectedProductVariant?.quantity_on_hand <= 0 ? "disabled" : ""}`}
                                  onClick={() => incrementQuantityHandler()}
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="back_product_name">
                            <h1
                              className="name"
                            >
                              {selectedProductVariant?.name}{" "}
                            </h1>
                          </div>

                          <div className="confirmbtn" onClick={() => addToCartitem(selectedProductVariant)}>
                            <button class="Checkout">confirm &nbsp;&nbsp;${renderPrice()} </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          ) : (
            <div className="col-md-12 no_product">{Constant.NO_PRODUCTS_AVAILABLE}</div>
          )}
        </div>
      </section>
    </>
  );
};

export default FilterProduct;
