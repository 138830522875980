import { createAsyncThunk } from "@reduxjs/toolkit";

export const submitOrderData = createAsyncThunk(
  "submitOrder",
  async (payload) => {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("MerchantToken", window.posabitmenuwidget.config.api_key);

      let raw = JSON.stringify(payload?.data);

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        // body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `https://qa-ecomm-app.posabit.com/mcx/oregon_tops/venue/${payload.slug}/v1/quotes/${payload?.reference_no}/finalize`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch submit order");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);


