import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";


export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
    quote: null,
  },

  reducers: {
    addToCart: (state, action) => {
      const { id, name, variants, quantity } = action.payload;
      
      const selectedVariant = variants[0];
      const {
        sku_id,
        unit,
        unit_type,
        quantity_on_hand,
        is_medical_only,
      } = selectedVariant;

      let selectedPrice = is_medical_only
        ? selectedVariant.discounted_med_price_cents ||
        selectedVariant.med_price_cents
        : selectedVariant.discounted_price_cents ||
        selectedVariant.price_cents;

      if (quantity <= quantity_on_hand) {
        const existingItemIndex = state.cart.findIndex(
          (item) => item.id === id && item.sku_id === sku_id
        );

        if (existingItemIndex !== -1) {
          state.cart[existingItemIndex].quantity += quantity;
        } else {
          state.cart.push({
            id,
            name,
            sku_id,
            unit,
            unit_type,
            price: selectedPrice,
            quantity,
            objId: uuidv4(),
            selectedVariant,
          });
        }
      } else {
        toast.error(`Cannot add product: ${name}. Insufficient quantity.`);
      }
    },

    incrementQuantity: (state, action) => {
      const { productId } = action.payload;
      //  console.log(state.cart);
      const item = state.cart.find((item) => item.id === productId);

      if (item && item.selectedVariant?.quantity_on_hand > item.quantity) {
        item.quantity++;
      } else {
        toast.error("Cannot add more items. Maximum quantity reached.");
      }
    },

    decrementQuantity: (state, action) => {
      const { productId } = action.payload;
      const item = state.cart.find(item => item.id === productId);

      if (item && item.quantity > 1) {
        item.quantity--;
      } else {
        toast.error("Quantity cannot be less than 1.");
      }
    },

    removeItem: (state, action) => {
      const { objId } = action.payload;
      const updatedCart = state.cart.filter(item => item.objId !== objId);
      state.cart = updatedCart;
    },

    setQuote: (state, action) => {
      state.quote = action.payload;
    },
  },
});

export const cartReducer = cartSlice.reducer;
export const selectQuote = state => state.cart.quote;
export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  setQuote,
} = cartSlice.actions;
