import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";


const Search = ({ setIsActive, handleClick }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const searchedString = queryParameters.get("search") || "";
    setSearchTerm(searchedString);
  }, [queryParameters]);

  const handleSearchInput = (e) => {
    setSearchTerm(e.target.value);
    let searched_string = e.target.value;
    let searched_string_length = searched_string.length;

    const queryParams = new URLSearchParams();

    if (searched_string.length > 2) {
      queryParams.set("search", searched_string);
      queryParams.set("name", searched_string);
    }


    const brandFilter = queryParameters.get("brands");
    const categoryFilter = queryParameters.get("categories");
    const flavorFilter = queryParameters.get("flavors");
    const effectFilter = queryParameters.get("effects");


    if (brandFilter) {
      queryParams.set("brands", brandFilter);
    }

    if (categoryFilter) {
      queryParams.set("categories", categoryFilter); // Set category filter in URL
    }

    if (flavorFilter) {
      queryParams.set("flavors", flavorFilter); // Set flavor filter in URL
    }

    if (effectFilter) {
      queryParams.set("effects", effectFilter); // Set effect filter in URL
    }

    if (searched_string_length > 2) {
      navigate(`/products?${queryParams.toString()}`);
    }
    if (searched_string_length === 0) {
      navigate("/products");
    }
  };

  return (
    <div className="search-box">
      <input
        className="search-txt"
        type="text"
        value={searchTerm}
        placeholder="Type to Search"
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
        onChange={handleSearchInput}
      />
      <span className="search-btn" onClick={handleClick}>
        <i className="fa fa-search"></i>
      </span>
    </div>
  );
};

export default Search;
