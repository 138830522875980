import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import slugify from "react-slugify";
import * as Constant from "../Constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  putQuotesUpdateItemsApi,
} from "../store/InitializeQuotesPostAPI";
import { primaryColor } from '../store/slice/ContentSlice.js';

const MiniCart = (props) => {
  // Update the dynamic color value in CSS custom property
const primaryColorValue = useSelector(primaryColor);
useEffect(() => {
  document.documentElement.style.setProperty('--dynamic-color', primaryColorValue);
}, [primaryColorValue]);
  const navigate = useNavigate();
  const { showModal, setShowModal } = props;
  const dispatch = useDispatch();
  const [isIconDisabled, setIsIconDisabled] = useState(false);
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );
  const defaultSlugId = slugId;
  const reference_no = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.reference_no
  );
  const items = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.items
  );

  const totals = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.totals
  );
  const totalPrice = totals?.total / 100;

  // const discountNames = useSelector(state => {
  //   const menuFeed = state.menuFeed;
  //   if (!menuFeed || !menuFeed.info || !menuFeed.info.product_data) {
  //     return null; // or handle the error in an appropriate way
  //   }
  
  //   const discounts = menuFeed.info.product_data.discounts;
  
  //   return items?.map(item => {
  //     // Assuming discount_ids represent discount names
  //     const itemDiscounts = item.discount_ids.map(discountId => {
  //       const discount = discounts.find(d => d.id === discountId);
  //       return discount ? discount.item_display_name : null;
  //     });
  
  //     return {
  //       itemId: item.id,
  //       discounts: itemDiscounts,
  //     };
  //   });
  // });

  const discountNames = useSelector(state => {
    const discounts = state?.discountData?.info?.coupons;
  
    return items?.map(item => {
      const itemDiscounts = item.discount_ids.map(discountId => {
        const discount = discounts?.find(d => d.id === discountId);
        return discount ? discount.display_name : null;
      });
  
      return {
        itemId: item.id,
        discounts: itemDiscounts,
      };
    });
  });
  
  
  const removeFromCart = (id) => {
    const slugId = localStorage.getItem("selectedVenue") || defaultSlugId;

    if (reference_no) {
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
          quote: {
            items: [
              ...items
                .filter((it) => {
                  return it.id !== id;
                })

                .map((its) => {
                  return {
                    sku_id: its?.sku_id,
                    unit: its?.unit,
                    unit_type: its?.unit_type,
                    quantity: its?.quantity,
                  };
                }),
              toast.info("Item removed from cart", {
                autoClose: 1000,
                closeOnClick: true,
              }),
            ],
          },
        })
      );
    }
  };
  const changeFinalQuantity = (item, inc) => {
    if (isIconDisabled) {
      return; // If the icon is disabled, do not update the quantity
    }
    const slugId = localStorage.getItem("selectedVenue") || defaultSlugId;
  
    dispatch(
      putQuotesUpdateItemsApi({
        slug: slugId,
        reference_no,
        quote: {
          items: items.map((it) => {
            if (
              it.sku_id === item?.sku_id &&
              it.unit === item?.unit &&
              it.unit_type === item?.unit_type
            ) {
              const newQuantity = inc ? it.quantity + 1 : it.quantity - 1;
              const updatedQuantity = Math.min(
                Math.max(newQuantity, 1), // Ensure quantity is at least 1
                it.quantity_on_hand // Ensure quantity doesn't exceed quantity_on_hand
              );
  
              if (updatedQuantity !== it.quantity) {
                dispatch({
                  type: 'PUT_QUOTES_UPDATE_ITEMS',
                  payload: {
                    sku_id: it.sku_id,
                    unit: it.unit,
                    unit_type: it.unit_type,
                    quantity: updatedQuantity,
                  },
                });
                toast.success("Product quantity updated in cart");
              } else if (updatedQuantity === 1 && !inc) {
                toast.warning("Minimum 1 quantity required");
              } else if (updatedQuantity === it.quantity) {
                toast.warning("You have reached the maximum quantity");
              }
  
              return { ...it, quantity: updatedQuantity };
            }
  
            return it;
          }),
        },
      })
    );
    // Disable the icon for 15 milliseconds
    if (inc) {
      setIsIconDisabled(true);
      setTimeout(() => {
        setIsIconDisabled(false);
      }, 2000);
    }
  };

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("./");
  };

  const navigateCheckOut = () => {
    // 👇️ navigate to /
    navigate("./checkout");
  };

  return (
    <>
      <div
        className={showModal ? "cart-container show-cart" : "cart-container"}
        role="document"
      >
        <div className="cart-container show-cart">
          <div className="top-container">
            <div className="cart-title">
              <h3>{Constant.SHOPPING_CART}</h3>
              <span
                className="close-cart"
                aria-label="Close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <i className="fa fa-close"></i>
              </span>
            </div>
            <div className="empty_cart text-center">
              <img src={window.location.origin + "/images/Group 778.png"} alt="Empty Cart" />
              <Link
                className="btn btn-info mt-4"
                to="/"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <i className="fa fa-cart-shopping"></i>
                {Constant.START_SHOPPING}{" "}

              </Link>
            </div>
          </div>
        </div>
        {items?.length > 0 && (
          <>
            <>
              <div className="cart-container show-cart">
                <div className="top-container">
                  <div className="cart-title">
                    <h3>{Constant.SHOPPING_CART}</h3>
                    <span
                      className="close-cart"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      <i className="fa fa-close"></i>
                    </span>
                  </div>
                  <div className="cart_scrolling">
                    {items?.map((item) => {
                      // Find the corresponding discounts for the current item
                      const itemDiscount = discountNames.find(d => d.itemId === item.id);
                      // const quantityArr = new Array(
                      //   item?.quantity_on_hand
                      // ).fill("");
                      return (
                        <div className="productminicart card_2">
                          <div className="img_box">
                            <img
                              src={item?.product_image?.lg}
                              alt="Product_Image"
                            />
                          </div>
                          <div className="det_box">
                            <div className="d-flex pt-2">
                              <h1 className="name">{item?.name}</h1>
                              <div className="static">
                                $ {((item.price / 100) * item?.quantity - (item?.discount / 100)).toFixed(2)}/1g
                                
                                {itemDiscount?.discounts.map((discountName, index) => (
                                  <p key={index}>
                                    {" "}
                                    {discountName && (
                                      <><i className="fa fa-tag"></i>{" "}<span>{discountName}</span></>
                                    )}
                                    
                                  </p>
                                ))}
                              </div>
                              <div>
                                <span>
                                  {/* {item?.unit} {item?.unit_type} */}
                                </span>

                                {/* <p>
                                  <span>{item?.quantity} x</span> $
                                  {(item?.price / 100).toFixed(2)}
                                </p> */}
                              </div>
                            </div>

                            <div className="price"><strike>$ {(item.price / 100 * item?.quantity).toFixed(2)}</strike></div>
                            <div className="itemquantity position-relative">

                              <div className="plus_minus_box box_label">
                                <label>Quantity</label>
                                <div className="plus_minus">
                                  <span
                                    className={`minus ${item?.quantity <= 1
                                      ? "disabled"
                                      : ""
                                      }`}
                                    onClick={() =>
                                      changeFinalQuantity(item, false)
                                    }
                                  >
                                    -
                                  </span>
                                  <span className="value">
                                    {item?.quantity}
                                  </span>
                                  <span
                                    className={`add ${isIconDisabled ? "disabled" : ""}`}
                                    onClick={() =>
                                      changeFinalQuantity(item, true)
                                    }
                                  >
                                    +
                                  </span>
                                </div>
                              </div>
                              <i
                                className="fa fa-trash"
                                onClick={() => removeFromCart(item?.id)}
                              ></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="Cart_footer_section">
                    <div className="d-flex justify-content-between px-2">
                      <p>{Constant.SUBTOTAL}</p>

                      <h5>
                        {Constant.DOLLAR}
                        {(totals?.subtotal / 100).toFixed(2)}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between px-2 cartdiscound">
                      <p>{Constant.DISCOUNT}</p>

                      <h5>
                        {Constant.MINUS_DOLLAR}
                        {(totals?.discount / 100).toFixed(2)}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between px-2">
                      <p>{Constant.TAX}</p>

                      <h5>
                        {Constant.DOLLAR}
                        {(totals?.tax / 100).toFixed(2)}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between px-2 totalamount">
                      <p className="fw-bold">{Constant.TOTAL}</p>

                      <h5>
                        {Constant.DOLLAR}
                        {totalPrice.toFixed(2)}
                      </h5>
                    </div>
                    <div className="cart_continue_btn">
                      <Link
                        to="./checkout"
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        <button
                          onClick={navigateCheckOut}

                          type="button"
                          className="btn btn-info Checkoutbtn"
                        >

                          {Constant.CHECK_OUT}
                        </button>
                      </Link>

                      <Link
                        to="./"
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        <button
                          onClick={navigateHome}
                          type="button"
                          className="btn  Checkout float-right"
                        >
                          <i class="fa fa-cart-shopping"></i>
                          {Constant.CONTINUE_SHOPPING}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </>
        )}
      </div>
      <ToastContainer />
      <div className="overlay"></div>
    </>
  );
};

export default MiniCart;
