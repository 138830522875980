import React  from "react";
import { Link } from "react-router-dom";
import slugify from "react-slugify";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import * as Constant from '../Constants';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { putQuotesUpdateItemsApi } from "../store/InitializeQuotesPostAPI";

const Cart = () => {
  const navigate = useNavigate();

  const items = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.items
  );
  const reference_no = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.reference_no
  );
 
  const totals = useSelector(
    (store) => store?.initialQuotes?.info?.quote?.totals
  );

  const totalPrice = totals.total / 100;
  const slugId = useSelector(
    (store) =>
      store?.merchant?.info?.merchant?.venues.filter((vn) => vn.selected)[0]
        ?.slug
  );

  const dispatch = useDispatch();
  const handleRemove = (id) => {
    if (reference_no) {
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
          quote: {
            items: [
              ...items
                .filter((it) => {
                  return it.id !== id;
                })
                .map((its) => {
                  return {
                    sku_id: its?.sku_id,
                    unit: its?.unit,
                    unit_type: its?.unit_type,
                    quantity: its?.quantity,
                  };
                }),
            ],
          },
        })
      );
      toast.success("Item removed from cart");
    }
  };

  const removeAll = () => {
    if (reference_no) {
      dispatch(
        putQuotesUpdateItemsApi({
          slug: slugId,
          reference_no,
          quote: {
            items: [],
          },
        })
      );
      toast.success("All items removed from cart");
    }
  };

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate("/");
  };
  const navigateCheckOut = () => {
    // 👇️ navigate to /
    navigate("/checkout");
  };
  const changeFinalQuantity = (item, inc) => {
    dispatch(
      putQuotesUpdateItemsApi({
        slug: slugId,
        reference_no,
        quote: {
          items: items.map((it) => {
            if (
              it.sku_id === item.sku_id &&
              it.unit === item.unit &&
              it.unit_type === item.unit_type
            ) {
              return {
                ...it,
                quantity: inc
                  ? it.quantity >= it.quantity_on_hand
                    ? it.quantity
                    : it.quantity + 1
                  : it.quantity <= 1
                  ? it.quantity
                  : it.quantity - 1,
              };
            } else {
              return it;
            }
          }),
        },
      })
    );
  };

  return (
    <>
      {items?.length === 0 ? (
        <div className="empty_cart text-center container">
          <img src="./images/Group 778.png" alt="Empty Cart" />
          <button className="btn btn-info mt-4 w-25" onClick={navigateHome}>
            {Constant.RETURN_TO_SHOPPING}
          </button>
        </div>
      ) : (
        <>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-12">
                {items.length > 1 ? (
                  <button
                    type="button"
                    className="close_button btn btn-danger"
                    onClick={removeAll}
                  >
                   {Constant.REMOVE_ALL}
                  </button>
                ) : (
                  true
                )}

                {items?.map((item) => (
                  <>
                    <div className="product_review_page">
                      <div className="product_image_section">
                        <img
                          src={item?.product_image?.lg}
                          alt={item?.name}
                          onClick={()=>navigate(item?.brand_id
                            ? `/${slugify(item?.brand)}/${slugify(item?.name)}`
                            : `/${slugify(item?.name)}`, {state:{
                              brands: item?.brand_id,
                              product_id: item?.product_id,
                          }})}
                        />
                        <div className="product_review_details">
                          
                            <h5
                            onClick={()=>navigate(item?.brand_id
                              ? `/${slugify(item?.brand)}/${slugify(item?.name)}`
                              : `/${slugify(item?.name)}`, {state:{
                                brands: item?.brand_id,
                                product_id: item?.product_id,
                            }})}
                            >{item.name}</h5>
                          
                          <p>
                            {Constant.THC} {item?.test_results?.thc?.current}
                            {item?.test_results?.thc?.unit_type}
                          </p>
                          <p>
                            {Constant.CBD} {item?.test_results?.cbd?.current}
                            {item?.test_results?.cbd?.unit_type}
                          </p>
                        </div>
                      </div>
                      <div className="plus_minus_box">
                        <div className="plus_minus">
                          <span
                            className="minus"
                            onClick={() => changeFinalQuantity(item, false)}
                          >
                            -
                          </span>
                          <span className="value">{item?.quantity}</span>
                          <span
                            className="add"
                            onClick={() => changeFinalQuantity(item, true)}
                          >
                            +
                          </span>
                        </div>
                      </div>
                      <div className="product_total_section">
                        <h5>
                          {/* <span>{item.quantity} x</span> */}$
                          {(item.price / 100).toFixed(2)}{" "}
                        </h5>
                        <span className="free_icon">
                          <i
                            className="fa fa-trash"
                            onClick={() => handleRemove(item.id)}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              </div>

              <div className="col-md-12 mt-4">
                <div className="preview_product_cart">
                  <div className="pick_up ">
                    <span className="pick_up_store1 fw-bold">{Constant.CART_TOTALS}</span>
                    <hr className="hr" />
                    <div className="total_pay">
                      <div className="amount">
                        <p>{Constant.SUBTOTAL}</p>
                        <p>{Constant.DOLLAR}
                        {(totals.subtotal / 100).toFixed(2)}</p>
                      </div>
                      <div className="amount">
                        <p>{Constant.DISCOUNT}</p>
                        <p>
                        {Constant.MINUS_DOLLAR}
                        {(totals?.discount / 100).toFixed(2)}
                      </p>
                      </div>
                      <div className="amount">
                        <p>{Constant.TAX}</p>
                        <p>{Constant.DOLLAR}{(totals.tax / 100).toFixed(2)}</p>
                      </div>
                      <div className="amount">
                        <p>{Constant.TOTAL}</p>
                        <p>{Constant.DOLLAR}{totalPrice.toFixed(2)}</p>
                      </div>
                      <div className="checkout">
                        <div className="inputcheck ">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Code"
                          />
                          <button type="button" className="btn apply">
                            {Constant.APPLY}
                          </button>
                        </div>
                        <button
                          type="button"
                          className="btn btn-info Checkoutbtn mx-2"
                          onClick={navigateHome}
                        >
                          {Constant.CONTINUE_SHOPPING}
                        </button>
                        <button
                          type="button"
                          className="btn btn-info Checkoutbtn"
                          onClick={navigateCheckOut}
                        >
                         {Constant.PROCEED_TO_CHECKOUT}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
