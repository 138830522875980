import { createSlice, current } from "@reduxjs/toolkit";

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: [],
  reducers: {
    addToWishlist: (state, action) => {
      
      const dummy = current(state);
      const found = dummy?.filter((st) => {
        if (st?.pId === action.payload.pId && st?.vId === action.payload.vId) {
          return true;
        } else {
          return false;
        }
      });
      if (found.length > 0) {
        return [
          ...dummy?.filter((st) => {
            if (
              st?.pId !== action.payload.pId &&
              st?.vId !== action.payload.vId
            ) {
              return st;
            }
          }),
        ];
      } else {
        // state.push(action.payload);
        return [...state, action.payload];
      }
    },
    // removeFromWishlist: (state, action) => {
    //   return state.filter((item) => item.id !== action.payload.id);
    // },
    removeFromWishlist: (state, action) => {
      const { pId, vId } = action.payload;
      return state.filter((item) => !(item.pId === pId && item.vId === vId));
    },
    
  },
});

export const { addToWishlist, removeFromWishlist } = wishlistSlice.actions;
export const selectWishlist = (state) => state.wishlist;
export default wishlistSlice.reducer;


